export default {
  name: "DownloadButton",
  props: {
    clickHandler: {
      type: Function,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
  },
};
