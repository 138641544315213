export default {
  name: 'CommonDialog',
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    }
  }
};
