import CommonDialog from '@/components/organisms/dialog/CommonDialog.vue';
import { fetchInsurerList } from "@/services/api/companyInfoService";

export default {
  name: 'InsurerNarrowdownDialog',
  components: {
    CommonDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    selectedInsurer: {
      type: String
    }
  },
  data() {
    return {
      title: "保険組合を選択してください",
      insurerList: [],
      selectedInsurer: null,
      filteredInsurerList: []
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
    getInsurerList() {
      // 保険組合リストを取得
      fetchInsurerList()
        .then((response) => {
          this.insurerList = response.data.insurer_list;
          this.filteredInsurerList = this.insurerList;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterInsurerList(query) {
      if (typeof query === "string" && query) {
        this.filteredInsurerList = this.insurerList.filter((insurer) =>
          insurer.toLowerCase().includes(query.toLowerCase())
        );
      } else {
        this.filteredInsurerList = this.insurerList;
      }
    },
    selectInsurer() {
      const selected = this.filteredInsurerList.find(
        (item) => item === this.selectedInsurer
      );
      if (this.selectedInsurer && selected) {
        this.$emit('update:selectedInsurer', this.selectedInsurer);
        this.closeDialog();
      }
    },
  },
  mounted() {
    this.getInsurerList();
  }
};
