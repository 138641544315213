<template>
  <v-row v-if="recruitInfo.length > 0">
    <v-col cols="12">
      <h2 style="background-color:#FFF3E0">採用情報</h2>
    </v-col>
    <v-col cols="12">
      <BaseTable :headers="headers" :data="recruitInfo" />
      <AnnotationShokubaLab />
    </v-col>
  </v-row>

  <br /><br />

  <v-row>
    <v-col cols="12">
      <h2 style="background-color:#FFF3E0">勤務実態に関する情報</h2>
    </v-col>
    <v-col cols="12">
      <BaseTable :headers="headers" :data="workStyleInfo" />
      <AnnotationShokubaLab />
    </v-col>
  </v-row>
</template>

<script src="./script.js"></script>
