<template>
  <CommonDialog
    :showDialog="showDialog"
    :title="title"
    @update:showDialog="closeDialog"
  >
    <v-list>
      <v-list-item>
        <v-row no-gutters class="search-text-row">
          <v-col cols="10">
            <v-combobox
              placeholder="保険組合名で探す"
              :items="filteredInsurerList"
              v-model="selectedInsurer"
              @input="filterInsurerList"
              variant="outlined"
              class="search-insurer"
              bg-color="white"
              hide-details="auto"
              density="comfortable"
            ></v-combobox>
          </v-col>
          <v-col cols="2">
            <v-btn @click="selectInsurer" class="search-button" flat>
              決定
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
  </CommonDialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
