<template>
  <button
    @click="clickHandler"
    class="download-button"
  >
    {{ buttonText }}
  </button>
</template>

<script src="./script.js"></script>
<style src="./style.css"></style>
