export default {
    myMap: new Map([
            ['Q1','Q1.業種等についてお答えください。（それぞれ1つだけ）'],
            ['Q2','Q2.組織形態と、当調査におけるご回答範囲についてお答えください。（1つだけ）'],
            ['Q3','Q3.当調査の回答範囲に含める従業員等の人数をご記入ください。'],
            ['Q4','Q4.健康経営優良法人2025（大規模法人部門）に申請しますか。（1つだけ）'],
            ['Q5','Q5.自社の株式を東京証券取引所に上場していますか。（1つだけ）'],
            ['Q6','Q6.当調査の評価結果および一部設問の回答内容について、経済産業省のウェブサイト等での公表を予定しています。貴法人の情報について公開してよろしいでしょうか。（1つだけ）'],
            ['Q7','Q7.2023年度末の正社員・非正社員のそれぞれについて、性別および年代構成別の人数をご記入ください。'],
            ['Q8','Q8.正社員の平均勤続年数と平均年齢を小数点第２位四捨五入でご記入ください。'],
            ['Q9','Q9.正社員の2023年度中の離職者数をご記入ください。'],
            ['Q10','Q10.2023年度の正社員の新卒・中途入社人数をそれぞれご記入ください。'],
            ['Q11','Q11.2023年度末時点の正社員について、拠点別のおおよその所属人数をご記入ください。'],
            ['Q12','Q12.正社員の職種別のおおよその構成比率をご記入ください。'],
            ['Q13','Q13.業績についてご記入ください。'],
            ['Q14','Q14.一人当たり医療費を把握している場合は、自社の従業員が加入する健保等保険者単位の金額と自社単位の金額について、それぞれご記入ください。'],
            ['Q15','Q15.自社の2023年度決算ベースの福利費を把握している場合はご記入ください。'],
            ['Q16','Q16.健康経営を経営戦略に位置付けて取り組み始めた時期をご記入ください。'],
            ['Q17','Q17.健康経営の推進に関する全社方針を社内向けに明文化していますか。（1つだけ）'],
            ['Q18','Q18.健康経営の推進に関してどのような内容を社外に開示していますか。'],
            ['Q19','Q19.経営トップ自ら、健康経営の推進のために以下の取り組みを行っていますか。（いくつでも）'],
            ['Q20','Q20.投資家との対話の中で、健康経営をどのように話題にしていますか。（いくつでも）'],
            ['Q21','Q21.取引先に対して健康経営および労働安全衛生の実施状況を把握し、取引先に取り組む事を推奨していますか。把握・推奨している内容をお答えください。（いくつでも）'],
            ['Q22','Q22.サプライチェーンにおいて取引先の健康経営の支援（健康経営に係るノウハウの提供、健康増進施策の共同実施等）を行っていますか。（いくつでも）実施している場合は、公表可能な支援を200文字以内でご記入ください。'],
            ['Q23','Q23.グローバルでの健康経営の実施方針をお答えください。（1つだけ）'],
            ['Q24','Q24.全社における健康経営の推進の最高責任者の役職は何ですか。（1つだけ）'],
            ['Q25','Q25.全社における健康経営の推進に関して、経営レベルの会議（取締役会や経営会議等）でどのような内容を議題にしていますか。（いくつでも）'],
            ['Q26','Q26.健康経営の推進の統括を行っている部署は何ですか。（1つだけ）'],
            ['Q27','Q27.健康経営の推進の専門職の担当者の人数をご記入ください。'],
            ['Q28','Q28.健康経営施策の実施にあたって、産業医または保健師はどのように関与していますか。'],
            ['Q29','Q29.主な健保組合等保険者の種別は何ですか。（1つだけ）また、その団体名と保険者番号をご記入ください。'],
            ['Q30','Q30.主な健保組合等保険者に対して、健康診断のデータを提供していますか。'],
            ['Q31','Q31.健康経営の推進のために、健保組合等保険者とどのような内容について協議・連携していますか。'],
            ['Q32','Q32.健康経営の推進に際して、管理職に対してどのような取り組みを行っていますか。（いくつでも）'],
            ['Q33','Q33.健康経営の推進に際して、従業員組織（労働組合や従業員代表等）との情報共有や協議等をどのように行っていますか。（いくつでも）'],
            ['Q34','Q34.各事業場・職場の健康経営推進担当者にどのような役割を定めていますか。（いくつでも）'],
            ['Q35','Q35.自社従業員の健康課題を踏まえ、健康経営の具体的な推進計画等を定めていますか。'],
            ['Q36','Q36.従業員の健康診断の実施について、各項目の集計の有無と具体的な数値をご記入ください。'],
            ['Q37','Q37.任意健診・検診（人間ドック、がん検診、肝炎ウイルス検査等）の受診率を向上させるために、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q38','Q38.定期健康診断や任意健診・検診の結果により、医療機関への受診が必要と判定された者に対して、医療機関への通院・治療を促すためにどのような取り組みを行っていますか。（1つだけ）'],
            ['Q39','Q39.2023年度のストレスチェックの実施についてお答えください。'],
            ['Q40','Q40.管理職に対して、従業員の健康保持・増進施策に関する教育をどのようなタイミングや頻度で行っていますか。（それぞれ1つだけ）'],
            ['Q41','Q41.従業員の健康意識の向上を図るために、健康保持・増進に関する教育をどのように行っていますか。（いくつでも）'],
            ['Q42','Q42.従業員のヘルスリテラシー向上のために、健診情報等を電子記録として活用するための取り組みを行っていますか。（いくつでも）'],
            ['Q43','Q43.従業員のヘルスリテラシー向上のための取組として、アプリやブラウザ上で従業員が自身のPHR（健診情報やライフログ等）を活用できるサービスを導入するなどの環境整備を行っていますか。（それぞれの「整備状況」欄に該当する「対象となるPHRの選択肢」の番号を入力）'],
            ['Q44','Q44.適切な働き方の実現に向けて、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q45','Q45.仕事と育児の両立支援としてどのような取り組みを行っていますか。（いくつでも）'],
            ['Q46','Q46.正社員の育児に関する制度の利用状況についてお答えください。'],
            ['Q47','Q47.仕事と介護の両立支援としてどのような取り組みを行っていますか。（いくつでも）'],
            ['Q48','Q48.コミュニケーション促進に向けて、どのような取り組み（研修・情報提供・宴会等を除く）を行っていますか。（いくつでも）'],
            ['Q49','Q49.私病等を持つ従業員への復職支援、就業と治療の両立支援としてどのような取り組みを行っていますか。（いくつでも）'],
            ['Q50','Q50.特定保健指導実施率向上のために、事業主側としてどのような取り組みを行っていますか。'],
            ['Q51','Q51.主な健保組合等保険者が実施する、特定健康診査および特定保健指導の実施率を把握していますか。（1つだけ）'],
            ['Q52','Q52.健康診断の結果を踏まえ一定の基準を満たした従業員に対する医師または保健師による保健指導（特定保健指導を除く）を行っていますか。（1つだけ）'],
            ['Q53','Q53.食生活改善に向けた具体的な支援（研修・情報提供を除く）として、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q54','Q54.運動習慣の定着に向けた具体的な支援（研修・情報提供を除く）として、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q55','Q55.従業員の生産性低下防止のために、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q56','Q56.女性特有の健康関連課題（※）に関する知識を得るための取り組み（セミナー等での教育）について、どのような従業員を対象に行っていますか。（いくつでも）また、行っている場合は、それぞれの属性の全従業員における2022年度の参加率をご記入ください。'],
            ['Q57','Q57.女性特有の健康関連課題に関する行動を促すために、どのような取り組みを行っていますか。'],
            ['Q58','Q58.一定の基準を超えた長時間労働者に対して、どのような取り組みを行っていますか。'],
            ['Q59','Q59.メンタルヘルス不調の予防や不調者への復職支援、就業と治療の両立支援として、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q60','Q60.常時使用しない従業員や、他社からの派遣社員を対象とした健康経営の取り組みはありますか。常時使用しない従業員、他社からの派遣社員それぞれについて回答ください。（それぞれいくつでも）'],
            ['Q61','Q61.高齢従業員特有の健康課題に特化した取り組みを行っていますか。（いくつでも）'],
            ['Q62','Q62.従業員の家族に対して実施しているものや家族も利用・参加できるものとして、どのような制度・施策がありますか。（いくつでも）'],
            ['Q63','Q63.感染症（インフルエンザ、麻しん・風しん、新型コロナウイルス感染症等）対策として、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q64','Q64.従業員の喫煙率を下げるために、どのような取り組みを行っていますか。（いくつでも）'],
            ['Q65','Q65.本社を含む国内全事業場の禁煙の状況はどのようになっていますか。（1つだけ）'],
            ['Q66','Q66.従業員の健康診断の結果について、各項目の集計の有無と具体的な数値をご記入ください。'],
            ['Q67','Q67.健康診断結果を踏まえた治療の状況についてお答えください。'],
            ['Q68','Q68.2023年度のストレスチェックの実施結果についてお答えください。（それぞれ1つだけ）'],
            ['Q69','Q69.正社員の一人当たり平均実労働時間等の状況についてお答えください。'],
            ['Q70','Q70.正社員の長時間労働の発生状況をお答えください。'],
            ['Q71','Q71.正社員の疾病による休職者数・退職者数の把握状況について、ご記入ください。'],
            ['Q72','Q72.従業員の生産性や組織の活性度等についてどのような評価指標を設定し、定期的に測定していますか。（いくつでも）'],
            ['Q73','Q73.今年度の健康経営の推進方針を検討するにあたり、昨年度までの健康経営の実施についてどのように効果検証を行っていますか。（1つだけ）'],
            ['Q74','Q74.Q17SQ4で回答された経営上の課題の改善状況や健康関連の最終的な目標指標のデータについてどのように検証していますか。（いくつでも）'],
            ['Q75','Q75.健康経営の取り組みの中で、特にアピールしたい独自の取り組みなどがあればご記入ください。'],
            ['Q76','Q76.健康経営に取り組む上でどのように外部委託事業者を活用していますか。（いくつでも）'],
            ['Q77','Q77.健康経営施策において外部事業者のサービスを活用する際に重要視することはどれですか。（いくつでも）'],
            ['Q78','Q78.以下の選択肢のうち、2023年度の経営レベルの会議で特に取り上げた課題と、対外的に発信している内容があればご回答ください。（それぞれいくつでも）'],
            ['Q79','Q79.健康経営に取り組むことでどのような効果を実感していますか。（いくつでも）'],
            ['Q80','Q80.過去健康経営優良法人に認定された法人にお伺いします。これまで健康経営優良法人に認定されたことでどのようなメリットがありましたか。（いくつでも）'],
            ['Q81','Q81.プレコンセプションケアについて知っていますか。（1つだけ）'],
            ['Q82','Q82.プレコンセプションケアについて、健康経営の一環として取り組みを実施していますか。（1つだけ）'],
            ['Q83','Q83.経済産業省では「仕事と介護の両立支援に関する経営者向けガイドライン」を公表し、介護両立支援に取り組む企業を増やしたいと考えています。このガイドラインについて、どの程度認知しているか教えてください。（1つだけ）'],
            ['Q84','Q84.仕事と育児または介護の両立支援について、ユニークな取り組みがあれば具体的な内容をご記入ください。'],
            ['Q85','Q85.次年度以降に、健康経営度調査票で回答いただいた内容について、投資・商品開発などを目的として機関投資家や金融機関等に貴社名付きで提供する場合、データ提供可否についてお答えください。（1つだけ）'],
        ])
};