import CommonDialog from '@/components/organisms/dialog/CommonDialog.vue';

export default {
  name: 'CompanySizeNarrowdownDialog',
  components: {
    CommonDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    selectedCompanySize: {
      type: String
    }
  },
  data() {
    return {
      title: "従業員規模を選択してください",
      companySizeList: [
        {
          min: 1,
          max: 100,
          label: "~100人"
        },
        {
          min: 101,
          max: 300,
          label: "101~300人"
        },
        {
          min: 301,
          max: 500,
          label: "301~500人"
        },
        {
          min: 501,
          max: 1000,
          label: "501~1000人"
        },
        {
          min: 1001,
          max: 3000,
          label: "1001~3000人"
        },
        {
          min: 3001,
          max: 5000,
          label: "3001~5000人"
        },
        {
          min: 5001,
          max: 10000,
          label: "5001~10000人"
        },
        {
          min: 10001,
          max: 30000,
          label: "10001~30000人"
        },
        {
          min: 30001,
          max: 500000,
          label: "30001人~"
        }
      ],
      selectedCompanySize: "",
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
    selectCompanySize(min, max, label) {
      this.$emit('update:selectedCompanySizeMin', min);
      this.$emit('update:selectedCompanySizeMax', max);
      this.$emit('update:selectedCompanySize', label);
      this.closeDialog();
    },
  },
};
