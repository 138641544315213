import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

export const fetchAllCompanies = () => {
  return axios.get(`${API_BASE_URL}/INIT`);
};

export const searchCompany = (
  companyName,
  industryCode,
  companyStatus,
  insurerName,
  employeeScaleMin,
  employeeScaleMax
) => {
  const params = {};
  if (companyName) params.researchWord = companyName;
  if (industryCode) params.industry = industryCode;
  if (companyStatus) params.status = companyStatus;
  if (insurerName) params.insurerName = insurerName;
  if (employeeScaleMin) params.employeeScaleMin = employeeScaleMin;
  if (employeeScaleMax) params.employeeScaleMax = employeeScaleMax;

  if (Object.keys(params).length === 0) {
    return axios.get(`${API_BASE_URL}/INIT`);
  }
  return axios.get(`${API_BASE_URL}/RESEARCH`, { params });
};

export const searchCompanyByHealthManagement = (
  healthManagementCode,
  kpiContent,
  industryCode,
  companyStatus,
  employeeScaleMin,
  employeeScaleMax
) => {
  const params = {};
  if (healthManagementCode) params.healthManagementIssueNum = healthManagementCode;
  if (kpiContent) params.KPIContent = kpiContent;
  if (industryCode) params.industry = industryCode;
  if (companyStatus) params.status = companyStatus;
  if (employeeScaleMin) params.employeeScaleMin = employeeScaleMin;
  if (employeeScaleMax) params.employeeScaleMax = employeeScaleMax;

  if (Object.keys(params).length === 0) {
    return axios.get(`${API_BASE_URL}/INIT`);
  }
  return axios.get(`${API_BASE_URL}/RESEARCH_HEALTH_MANAGEMENT`, { params });
};

export const searchCompanyByHealthMeasure = (
  healthMeasureCode,
  industryCode,
  companyStatus,
  employeeScaleMin,
  employeeScaleMax
) => {
  const params = {};
  if (healthMeasureCode) params.healthMeasureNum = healthMeasureCode;
  if (industryCode) params.industry = industryCode;
  if (companyStatus) params.status = companyStatus;
  if (employeeScaleMin) params.employeeScaleMin = employeeScaleMin;
  if (employeeScaleMax) params.employeeScaleMax = employeeScaleMax;

  if (Object.keys(params).length === 0) {
    return axios.get(`${API_BASE_URL}/INIT`);
  }
  return axios.get(`${API_BASE_URL}/RESEARCH_HEALTH_MEASURE`, { params });
};

export const fetchCompaniesByText = (searchText) => {
  const encodedSearchText = encodeURIComponent(searchText);
  return axios.get(
    `${API_BASE_URL}/RESEARCH?researchWord=${encodedSearchText}`
  );
};

export const fetchCompaniesByIndustry = (industryCode) => {
  return axios.get(
    `${API_BASE_URL}/RESEARCH?industry=${industryCode}`
  );
};

export const fetchCompaniesByInsurer = (insurerName) => {
  return axios.get(
    `${API_BASE_URL}/RESEARCH?insurerName=${insurerName}`
  );
};

export const fetchInsurerList = () => {
  return axios.get(`${API_BASE_URL}/INSURER_LIST`);
};

export const fetchCompanyCountByIndustry = () => {
  return axios.get(`${API_BASE_URL}/COMPANY_COUNT_BY_INDUSTRY`);
};

export const fetchCompanyDetails = (code) => {
  return axios.get(`${API_BASE_URL}/DETAIL_1?code=${code}`);
};

export const fetchCompanyDetails2 = (code) => {
  return axios.get(`${API_BASE_URL}/DETAIL_2?code=${code}`);
};

export const fetchCompanyDetails3 = (code) => {
  return axios.get(`${API_BASE_URL}/DETAIL_3?code=${code}`);
};

export const fetchCompanyDetails4 = (code) => {
  return axios.get(`${API_BASE_URL}/DETAIL_4?code=${code}`);
};

export const fetchCompanyBasicInfo = (code) => {
  return axios.get(
    `${API_BASE_URL}/BASIC_INFO?action_code=${String(code)}`
  );
}
export const fetchCpmpanyWorkStyleInfo = (code) => {
  return axios.get(`${API_BASE_URL}/WORK_STYLE_INFO?action_code=${code}`);
};
