export const COMPANY_LABELS = {
  LISTED: {
    LABEL: "上場",
    FLAG: "listed_status",
  },
  LISTED2: {
    LABEL: "上場",
    FLAG: "list_flag",
  },
  HEALTH_MANAGEMENT_BRAND: {
    LABEL: "健康経営銘柄",
    FLAG: "brand_flag",
  },
  BIG_GOOD_CORPORATION: {
    LABEL: "優良法人（大）",
    FLAG: "big_good_flag",
  },
  SMALL_GOOD_CORPORATION: {
    LABEL: "優良法人（中小）",
    FLAG: "small_good_flag",
  },
  WHITE_500: {
    LABEL: "ホワイト500",
    FLAG: "white500_flag",
  },
  BRIGHT_500: {
    LABEL: "ブライト500",
    FLAG: "bright500_flag",
  },
  NEXT_BRIGHT_1000: {
    LABEL: "ネクストブライト1000",
    FLAG: "next_bright1000_flag",
  },
};

export const CHIP_CONFIGS = [
  {
    label: COMPANY_LABELS.LISTED.LABEL,
    flag: COMPANY_LABELS.LISTED.FLAG,
    color: "red-darken-4",
  },
  {
    label: COMPANY_LABELS.LISTED2.LABEL,
    flag: COMPANY_LABELS.LISTED2.FLAG,
    color: "red-darken-4",
  },
  {
    label: COMPANY_LABELS.HEALTH_MANAGEMENT_BRAND.LABEL,
    flag: COMPANY_LABELS.HEALTH_MANAGEMENT_BRAND.FLAG,
    color: "lime-darken-3",
  },
  {
    label: COMPANY_LABELS.WHITE_500.LABEL,
    flag: COMPANY_LABELS.WHITE_500.FLAG,
    color: "primary",
  },
  {
    label: COMPANY_LABELS.BRIGHT_500.LABEL,
    flag: COMPANY_LABELS.BRIGHT_500.FLAG,
    color: "primary",
  },
  {
    label: COMPANY_LABELS.NEXT_BRIGHT_1000.LABEL,
    flag: COMPANY_LABELS.NEXT_BRIGHT_1000.FLAG,
    color: "primary",
  },
  {
    label: COMPANY_LABELS.BIG_GOOD_CORPORATION.LABEL,
    flag: COMPANY_LABELS.BIG_GOOD_CORPORATION.FLAG,
    color: "primary",
  },
  {
    label: COMPANY_LABELS.SMALL_GOOD_CORPORATION.LABEL,
    flag: COMPANY_LABELS.SMALL_GOOD_CORPORATION.FLAG,
    color: "primary",
  },
];

export const HEALTH_ISSUE_CATEGORIES = [
  {
    index: 1,
    category: "健康状態にかかわらず全従業員に対する疾病の発⽣予防",
    rank: "",
    average: "",
    questions: "Q36、Q37、Q40、Q41、Q42、Q43、Q53、Q54、Q66(a)、Q66(c)",
  },
  {
    index: 2,
    category: "⽣活習慣病などの疾病の⾼リスク者に対する重症化予防",
    rank: "",
    average: "",
    questions: "Q38、Q50、Q51、Q52、Q66(f)〜(h)、Q67",
  },
  {
    index: 3,
    category:
      "メンタルヘルス不調等のストレス関連疾患の発⽣予防・早期発⾒・対応",
    rank: "",
    average: "",
    questions: "Q39、Q59、Q68",
  },
  {
    index: 4,
    category: "従業員の⽣産性低下防⽌・事故発⽣予防",
    rank: "",
    average: "",
    questions: "Q55、Q66(d)、Q66(e)",
  },
  {
    index: 5,
    category: "⼥性特有の健康関連課題への対応、⼥性の健康保持・増進",
    rank: "",
    average: "",
    questions: "Q56、Q57",
  },
  {
    index: 6,
    category: "休職後の職場復帰、仕事と治療の両⽴",
    rank: "",
    average: "",
    questions: "Q49、Q71",
  },
  {
    index: 7,
    category: "労働時間の適正化、ワークライフバランス・⽣活時間の確保",
    rank: "",
    average: "",
    questions: "Q44、Q58、Q69、Q70",
  },
  {
    index: 8,
    category: "仕事と育児の両立、仕事と介護の両立",
    rank: "",
    average: "",
    questions: "Q45、Q46、Q47",
  },
  {
    index: 9,
    category: "従業員間のコミュニケーションの促進",
    rank: "",
    average: "",
    questions: "Q48、Q72",
  },
  {
    index: 10,
    category: "従業員の感染症予防（インフルエンザなど）",
    rank: "",
    average: "",
    questions: "Q63",
  },
  {
    index: 11,
    category: "従業員の喫煙率低下",
    rank: "",
    average: "",
    questions: "Q64、Q65、Q66(b)",
  },
];

export const ASSESSMENT_ITEM_LIST = [
  {
    name: "総合評価",
    weight: "-",
    companyRank: 0,
    avarageRankIndustory: 0,
    details: [],
  },
  {
    name: "経営理念・⽅針",
    weight: 3,
    companyRank: 0,
    avarageRankIndustory: 0,
    details: [
      {
        name: "経営理念・方針",
        weight: "明文化・健康経営の戦略",
        questions: "Q17、Q19",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "情報開示・他社への普及",
        questions: "Q18、Q21、Q22",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
    ],
  },
  {
    name: "組織体制",
    weight: 2,
    companyRank: 0,
    avarageRankIndustory: 0,
    details: [
      {
        name: "組織体制",
        weight: "経営層の関与",
        questions: "Q24、Q25",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "実施体制",
        questions: "Q26、Q27、Q28、Q30、Q31",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "従業員への浸透",
        questions: "Q32、Q33、Q34",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
    ],
  },
  {
    name: "制度・施策実行",
    weight: 2,
    companyRank: 0,
    avarageRankIndustory: 0,
    details: [
      {
        name: "制度・施策実行",
        weight: "目標設定、健診・検診等の活用",
        questions: "Q35、Q36、Q37、Q38、Q39",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "健康経営の実践に向けた土台づくり",
        questions: "Q40、Q41、Q42、Q43、Q44、Q45、Q46、Q47、Q48、Q49",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "保険指導",
        questions: "Q50、Q51、Q52",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "生活習慣の改善",
        questions: "Q53、Q54、Q55、Q64、Q65",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "その他の施策",
        questions: "Q56、Q57、Q58、Q59、Q60、Q61、Q62、Q63",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
    ],
  },
  {
    name: "評価・改善",
    weight: 3,
    companyRank: 0,
    avarageRankIndustory: 0,
    details: [
      {
        name: "評価改善",
        weight: "健康診断・ストレスチェック",
        questions: "Q66、Q67、Q68、Q72",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "労働時間・休職",
        questions: "Q69、Q70、Q71",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
      {
        name: "",
        weight: "課題単位・施策全体の効果検証・改善",
        questions: "Q73、Q74",
        companyRank: 0,
        avarageRankIndustory: 0,
      },
    ],
  },
];

export const HEALTH_MANAGEMENT_LIST = [
  "組織のパフォーマンス向上",
  "組織の活性化",
  "中長期的な市場価値向上",
  "企業の社会的責任",
  "人材定着や採用力向上",
  "その他"
];

export const HEALTH_MEASURE_LIST = HEALTH_ISSUE_CATEGORIES.map(item => item.category);

export const ALL_NUM_OF_COMPANY_COUNT = 22846; // 全企業数（フィードバックシート公開または顕彰を受けた企業数）
export const NUM_OF_COMPANY_COUNT = 3869; // 調査票提出企業数
export const NUM_OF_COMPANY_COUNT_THAT_PUBLISH_SHEETS = 2679; // フィードバックシート公開企業数
