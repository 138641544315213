import { fetchCompanyDetails3 } from "@/services/api/companyInfoService";
import BaseTable from "@/components/molecules/base-table/BaseTable.vue";

// import CompanyInfo from "@/views/CompanyInfo.vue";
export default {
  components: {
    BaseTable,
  },
  props: ["companyInfo"],
  data: () => ({
    tab: null,
    board_meeting_agenda: [
      {
        name: 1,
        apply: "",
        body: "健康経営の具体的な取り組みと期待する効果のつながりの整理（戦略マップなど）を基にした全体方針",
      },
      {
        name: 2,
        apply: "〇",
        body: "従業員の健康状態や生活習慣に関する健康経営の取組効果（スコアリングレポート内容を踏まえて議論するなど）",
      },
      {
        name: 3,
        apply: "〇",
        body: "経営上の課題に対する健康経営の取り組みの効果（健康経営度調査フィードバックシート内容を踏まえて議論するなど）",
      },
      {
        name: 4,
        apply: "〇",
        body: "健康経営の取り組みに関する外部からの評価（各種認定取得、IRや採用面での効果など）",
      },
      {
        name: 5,
        apply: "〇",
        body: "健康経営の取り組みに関する対外的な発信内容（投資家との対話、求人でのPRなど）",
      },
      {
        name: 6,
        apply: "〇",
        body: "保険者との連携",
      },
      {
        name: 7,
        apply: "〇",
        body: "その他",
      },
    ],
    management_meeting_agenda: [
      {
        name: 1,
        apply: "",
        body: "経営計画上での健康経営の位置づけや進捗の確認",
      },
      {
        name: 2,
        apply: "〇",
        body: "従業員の健康課題の分析結果",
      },
      {
        name: 3,
        apply: "〇",
        body: "健康経営の推進に関する体制",
      },
      {
        name: 4,
        apply: "〇",
        body: "従業員の業務パフォーマンス",
      },
      {
        name: 5,
        apply: "〇",
        body: "従業員への健康経営の浸透度合い",
      },
      {
        name: 6,
        apply: "〇",
        body: "保険者との連携",
      },
      {
        name: 7,
        apply: "〇",
        body: "その他",
      },
    ],
    times_on_agenda: [
      {
        name: "(a)取締役会",
        num1: "",
        num2: "",
      },
      {
        name: "(b)経営レベルの会議（取締役会以外）",
        num1: "",
        num2: "",
      },
    ],
    various_measures: [
      {
        type: 1,
        category: "健康保持・増進に関する教育",
        section: "",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 4,
        category: "育児・介護に関する知識を得るための取り組み（セミナー等での教育）",
        section: "",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "コミュニケーション促進",
        section: "従業員参加",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "",
        section: "環境整備",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "食生活改善",
        section: "従業員参加",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "",
        section: "環境整備",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "運動習慣定着",
        section: "従業員参加",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 2,
        category: "",
        section: "環境整備",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "",
        bFlag: "",
        cFlag: "",
      },
      {
        type: 3,
        category: "⼥性特有の健康関連課題に関する知識を得るための取り組み（セミナー等での教育）",
        section: "",
        summary: "",
        body: "",
        aRate: "",
        bRate: "",
        cRate: "",
        aFlag: "〇",
        bFlag: "〇",
        cFlag: "〇",
      },
    ],
    meeting_agenda_header: [
      { text: "番号", value: "name", width: "10%" },
      { text: "実施", value: "apply", width: "10%" },
      { text: "議題にしている内容", value: "body", width: "80%" },
    ],
    times_on_agenda_header: [
      { text: "", value: "name", width: "30%" },
      { text: "①総実施回数", value: "num1", width: "30%" },
      { text: "②健康経営を議題にした回数", value: "num2", width: "30%" },
    ],
  }),
  methods: {
    getHealthAgendaSymbol(value) {
      return value ? "〇" : "×";
    },
  },
  mounted() {
    fetchCompanyDetails3(this.companyInfo.code).then((response) => {
      this.board_meeting_agenda[0].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_1
      );
      this.board_meeting_agenda[1].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_2
      );
      this.board_meeting_agenda[2].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_3
      );
      this.management_meeting_agenda[3].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_4
      );
      this.management_meeting_agenda[4].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_5
      );
      this.board_meeting_agenda[5].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_6
      );
      this.board_meeting_agenda[6].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_a_7
      );
      this.management_meeting_agenda[0].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_1
      );
      this.management_meeting_agenda[1].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_2
      );
      this.management_meeting_agenda[2].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_3
      );
      this.management_meeting_agenda[3].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_4
      );
      this.management_meeting_agenda[4].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_5
      );
      this.management_meeting_agenda[5].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_6
      );
      this.management_meeting_agenda[6].apply = this.getHealthAgendaSymbol(
        response.data.health_management_agenda_b_7
      );

      this.times_on_agenda[0].num1 = response.data.agenda_count_a_1 + "回";
      this.times_on_agenda[0].num2 = response.data.agenda_count_a_2 + "回";
      this.times_on_agenda[1].num1 = response.data.agenda_count_b_1 + "回";
      this.times_on_agenda[1].num2 = response.data.agenda_count_b_2 + "回";

      this.various_measures[0].aRate = response.data.health_education_promotion_a;
      this.various_measures[0].bRate = response.data.health_education_promotion_b;
      this.various_measures[1].aFlag = this.getHealthAgendaSymbol(
        response.data.childcare_caregiving_education_a_flag
      );
      this.various_measures[1].bFlag = this.getHealthAgendaSymbol(
        response.data.childcare_caregiving_education_b_flag
      );
      this.various_measures[1].cFlag = this.getHealthAgendaSymbol(
        response.data.childcare_caregiving_education_c_flag
      );
      this.various_measures[1].aRate = response.data.childcare_caregiving_education_a_percent;
      this.various_measures[1].bRate = response.data.childcare_caregiving_education_b_percent;
      this.various_measures[1].cRate = response.data.childcare_caregiving_education_c_percent;
      this.various_measures[2].summary =
        response.data.communication_promotion_choice_content_emp;
      this.various_measures[2].body =
        response.data.communication_promotion_approach_content_emp;
      this.various_measures[2].aRate = response.data.communication_promotion_emp_a;
      this.various_measures[2].bRate = response.data.communication_promotion_emp_b;
      this.various_measures[3].summary =
        response.data.communication_promotion_choice_content_env;
      this.various_measures[3].body =
        response.data.communication_promotion_approach_content_env;
      this.various_measures[3].aRate = response.data.communication_promotion_env_a;
      this.various_measures[3].bRate = response.data.communication_promotion_env_b;
      this.various_measures[4].summary = response.data.diet_improvement_choice_content_emp;
      this.various_measures[4].body = response.data.diet_improvement_approach_content_emp;
      this.various_measures[4].aRate = response.data.diet_improvement_emp_a;
      this.various_measures[4].bRate = response.data.diet_improvement_emp_b;
      this.various_measures[5].summary = response.data.diet_improvement_choice_content_env;
      this.various_measures[5].body = response.data.diet_improvement_approach_content_env;
      this.various_measures[5].aRate = response.data.diet_improvement_env_a;
      this.various_measures[5].bRate = response.data.diet_improvement_env_b;
      this.various_measures[6].summary =
        response.data.exercise_habit_formation_choice_content_emp;
      this.various_measures[6].body =
        response.data.exercise_habit_formation_approach_content_emp;
      this.various_measures[6].aRate = response.data.exercise_habit_formation_emp_a;
      this.various_measures[6].bRate = response.data.exercise_habit_formation_emp_b;
      this.various_measures[7].summary =
        response.data.exercise_habit_formation_choice_content_env;
      this.various_measures[7].body =
        response.data.exercise_habit_formation_approach_content_env;
      this.various_measures[7].aRate = response.data.exercise_habit_formation_env_a;
      this.various_measures[7].bRate = response.data.exercise_habit_formation_env_b;
      this.various_measures[8].aFlag = this.getHealthAgendaSymbol(
        response.data.women_health_education_a_1
      );
      this.various_measures[8].bFlag = this.getHealthAgendaSymbol(
        response.data.women_health_education_a_2
      );
      this.various_measures[8].cFlag = this.getHealthAgendaSymbol(
        response.data.women_health_education_a_3
      );
      this.various_measures[8].aRate = response.data.women_health_education_b_1;
      this.various_measures[8].bRate = response.data.women_health_education_b_2;
      this.various_measures[8].cRate = response.data.women_health_education_b_3;
    });
  },
};
