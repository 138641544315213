import HeaderDialog from "@/components/organisms/dialog/header-dialog/HeaderDialog.vue";

export default {
  components: {
    HeaderDialog
  },
  data() {
    return {
      nav_lists: [
        {
          name: "ACTION!健康経営",
          href: "https://kenko-keiei.jp/",
          prependAvatarUrl:
            "https://th.bing.com/th?id=ODLS.79b77d17-8ed2-4031-aa08-ef6822828d4d&w=32&h=32&qlt=90&pcl=fffffa&o=6&pid=1.2",
        },
        {
          name: "フィードバックを書く",
          href: "https://forms.office.com/r/hgR3AAstNN",
          prependAvatarUrl:
            "https://menter.jp/blog/wp-content/uploads/2023/02/forms1.webp",
        },
      ],
      mainGreen: 'var(--main-green)',
      showDialog: false,
      dialogTitle: 'タイトル',
      dialogContent: '内容',
      dialog: {
        documents: {
          title: '関連する社内資料',
          content: `
            <div style="font-size: 14px; line-height: 2;">
              <h3>▼基礎から改めて復習</h3>
              ・<a href="https://arm8769.sharepoint.com/:p:/s/msteams_291da8_634291/EXTF-zplrB1FufSY0bUY4OcBJ92xRMrTSeKNyzgCvBrJ9Q?e=KAL5YT&isSPOFile=1" target="_blank" class="header-doc-link">【投影用】20240424開催_健康経営基礎セミナー.pptx</a><br>
              ・<a href="https://teachme.jp/108749/manuals/13176454" target="_blank" class="header-doc-link">営業Playbook</a><br>
              <br>
              <h3>▼調査票・フィードバックシートを学ぶ</h3>
              ・<a href="https://arm8769.sharepoint.com/:p:/s/msteams_149407_554326/Ec-QlIHrVyZDg3djsNq6j3kBUb1m81PqWeLc4AD7FWQRKA?isSPOFile=1" target="_blank" class="header-doc-link">240222開催_【当日資料】健康経営優良法人2025 スタートアップセミナー for 大規模法人部門.pptx</a><br>
              ・<a href="https://arm8769.sharepoint.com/:p:/s/msteams_149407_554326/ES1dm1H950pJpKlYByBoLEkBGongfjQOzhgrPBD6VBwWdA?isSPOFile=1" target="_blank" class="header-doc-link">240124開催_【当日資料】健康経営優良法人2024フィードバックシート徹底活用セミナー.pptx</a><br>
              <br>
              <h3>▼当社の商品と健康経営がどうつながるのか確認</h3>
              ・<a href="https://www.armg.jp/sustainability/healthcare/" target="_blank" class="header-doc-link">アドバンテッジリスクマネジメントグループの健康経営 | 株式会社 アドバンテッジリスクマネジメント (armg.jp)</a><br>
              ・<a href="https://teams.microsoft.com/l/message/19:4b3f971b64fd4e0ba06b011133e1f473@thread.skype/1695345770409?tenantId=137483a6-e54e-444b-9d47-ee74490cbd66&groupId=307e7752-3941-443e-b32a-ac4cbee84394&parentMessageId=1695345770409&teamName=%E3%82%A2%E3%83%89%E3%83%90%E3%83%B3%E3%83%86%E3%83%83%E3%82%B8%E3%83%AA%E3%82%B9%E3%82%AF%E3%83%9E%E3%83%8D%E3%82%B8%E3%83%A1%E3%83%B3%E3%83%88%E3%83%81%E3%83%BC%E3%83%A0&channelName=%E3%80%90%E5%85%A8%E7%A4%BE%E3%80%9101_%E7%AB%B6%E5%90%88%E6%83%85%E5%A0%B1%E7%99%BA%E4%BF%A1&createdTime=1695345770409" target="_blank" class="header-doc-link">【Teams】【勉強会周知】【全部門営業職】 9月26日(火)15時より勉強会を行います</a><br>
              ・<a href="https://teams.microsoft.com/l/message/19:ac5e3aac38964500b6230711ec703d89@thread.skype/1697707378971?tenantId=137483a6-e54e-444b-9d47-ee74490cbd66&groupId=4d05ac8f-b188-4b8b-b9d0-a939f8c571f5&parentMessageId=1697707378971&teamName=%E3%80%90%E5%85%B1%E6%9C%89%E3%80%91MM%E9%83%A8%E9%96%80&channelName=%E2%98%85%E3%81%93%E3%82%93%E3%81%AA%E8%B3%87%E6%96%99%E3%81%A4%E3%81%8F%E3%82%8A%E3%81%BE%E3%81%97%E3%81%9F%EF%BC%81%EF%BC%86%E3%81%82%E3%82%8A%E3%81%BE%E3%81%9B%E3%82%93%E3%81%8B%EF%BC%9F&createdTime=1697707378971" target="_blank" class="header-doc-link">【Teams】杉浦 亜美: 2024年度 健康経営度調査票のARM商材にかかわる設問一覧</a><br>
              ・<a href="https://arm8769.sharepoint.com/:p:/r/sites/msteams_149407_554326/Shared%20Documents/24%E5%B9%B403%E6%9C%8822%E6%97%A5%20%E3%82%A2%E3%83%89%E3%83%90%E3%83%B3%E3%83%86%E3%83%83%E3%82%B8%E3%81%AE%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B62024%E7%B7%8F%E6%8B%AC%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC/03_%E6%8A%95%E5%BD%B1%E8%B3%87%E6%96%99%EF%BC%88%E3%82%A6%E3%82%A7%E3%83%93%E3%83%8A%E3%83%BC%E6%8A%95%E5%BD%B1%E7%94%A8%EF%BC%89/240322%E9%96%8B%E5%82%AC_%E3%80%90%E5%BD%93%E6%97%A5%E8%B3%87%E6%96%99%E3%80%91%E3%82%A2%E3%83%89%E3%83%90%E3%83%B3%E3%83%86%E3%83%83%E3%82%B8%E3%81%AE%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B62024%E7%B7%8F%E6%8B%AC%E3%82%BB%E3%83%9F%E3%83%8A%E3%83%BC%EF%BD%9E%E4%BA%BA%E4%BA%8B%E3%81%8C%E8%AA%9E%E3%82%8B%E3%80%8C%E3%83%87%E3%83%BC%E3%82%BF%E3%81%A7%E3%81%A4%E3%81%AA%E3%81%8C%E3%82%8B%E3%80%8D%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B6%EF%BD%9E.pptx?d=w75865a8eb6a44edc9afc526a40a50588&csf=1&web=1&e=hGIeHr&isSPOFile=1" target="_blank" class="header-doc-link">【当日資料】アドバンテッジの健康経営2024総括セミナー～人事が語る「データでつながる」健康経営～.pptx</a><br>
              <br>
              <h3>▼顧客に渡すことを前提とした資料</h3>
              ・<a href="https://arm8769.sharepoint.com/:b:/r/sites/MHC/MHC/04_%E5%95%86%E5%93%81%E6%A8%AA%E6%96%AD%E6%8B%A1%E8%B2%A9%E8%B3%87%E6%9D%90%E3%80%90%E5%85%AC%E5%BC%8F%E7%89%88%E3%80%91/06_HP%E7%94%A8%E3%81%AE%E8%B3%87%E6%96%99%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89/HP%E6%8E%B2%E8%BC%89%E8%B3%87%E6%96%99/%E5%81%A5%E8%A8%BA%EF%BC%8F%E7%94%A3%E6%A5%AD%E5%8C%BB%E3%83%BB%E4%BF%9D%E5%81%A5%E5%B8%AB/DL%E8%B3%87%E6%96%99_%E5%81%A5%E8%A8%BA%E7%B5%90%E6%9E%9C%E3%83%87%E3%83%BC%E3%82%BF%E6%B4%BB%E7%94%A8%E3%82%AC%E3%82%A4%E3%83%89%E3%83%96%E3%83%83%E3%82%AF-%E5%8A%B9%E6%9E%9C%E7%9A%84%E3%81%AA%E7%94%A3%E6%A5%AD%E4%BF%9D%E5%81%A5%E6%B4%BB%E5%8B%95%E3%82%92%E6%8E%A8%E9%80%B2%E3%81%97%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B6%E3%82%92%E5%AE%9F%E7%8F%BE%E3%81%99%E3%82%8B%E3%81%9F%E3%82%81%E3%81%AB.pdf?csf=1&web=1&e=wobdKT&isSPOFile=1" target="_blank" class="header-doc-link">DL資料_健診結果データ活用ガイドブック-効果的な産業保健活動を推進し健康経営を実現するために.pdf</a><br>
              ・<a href="https://arm8769.sharepoint.com/:b:/r/sites/MHC/MHC/04_%E5%95%86%E5%93%81%E6%A8%AA%E6%96%AD%E6%8B%A1%E8%B2%A9%E8%B3%87%E6%9D%90%E3%80%90%E5%85%AC%E5%BC%8F%E7%89%88%E3%80%91/06_HP%E7%94%A8%E3%81%AE%E8%B3%87%E6%96%99%E3%83%80%E3%82%A6%E3%83%B3%E3%83%AD%E3%83%BC%E3%83%89/HP%E6%8E%B2%E8%BC%89%E8%B3%87%E6%96%99/%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B6/DL%E8%B3%87%E6%96%99_%E7%A4%BE%E5%93%A1%E3%81%8C%E2%80%9C%E5%8F%82%E5%8A%A0%E3%81%97%E3%81%9F%E3%81%8F%E3%81%AA%E3%82%8B%E2%80%9D%E5%81%A5%E5%BA%B7%E7%B5%8C%E5%96%B6%E6%96%BD%E7%AD%96%E3%81%AE%E3%82%B3%E3%83%84%E3%81%A8%E3%81%AF%EF%BC%9F.pdf?csf=1&web=1&e=1nO2Nt&isSPOFile=1" target="_blank" class="header-doc-link">DL資料_社員が“参加したくなる”健康経営施策のコツとは？.pdf</a><br>
              <br>
              <h3>▼関連記事</h3>
              ・<a href="https://www.armg.jp/journal/315-2/" target="_blank" class="header-doc-link">【JOURNAL】健康診断業務システム化のメリット、まとめました。【健康経営を大きく推進させる最初の一歩に】</a><br>
              ・<a href="https://www.armg.jp/journal/307-2/" target="_blank" class="header-doc-link">【JOURNAL】健康経営戦略マップの作り方と活用の仕方を徹底解説～戦略マップで意義あるPDCAを回そう～</a><br>
              ・<a href="https://www.armg.jp/journal/303-2/" target="_blank" class="header-doc-link">【JOURNAL】「健康経営の取り組み、正直どう思ってる？」従業員1,000人に聞いた調査結果を大発表! ～従業員を巻き込んで推進するためのポイントは○○？～</a><br>
           </div>
          `
        },
        aboutData: {
          title: 'データについて',
          content: '・現在公開中の情報（令和6年度）が閲覧可能です<br>・企業一覧では、【フィードバックシート非公開】の各種頭彰を取得している企業のデータも表示されます。'
        },
      }
    };
  },
  methods: {
    handleClick() {
      this.$router.push({
        name: "homepage",
        params: { industryName: "全業種" },
      });
    },
    openDialog(title, content) {
      this.dialogTitle = title;
      this.dialogContent = content;
      this.showDialog = true;
    }
  }
};
