import CommonDialog from '@/components/organisms/dialog/CommonDialog.vue';

export default {
  name: 'HeaderDialog',
  components: {
    CommonDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    }
  }
};
