import Header from "@/components/organisms/app-header/AppHeader.vue";

export default {
  components: {
    Header,
  },
  data() {
    return {
      reloadflag: true,
    };
  },
  watch: {
    $route(routeInstance) {
      this.createTitleDesc(routeInstance);
    },
  },
  methods: {
    createTitleDesc: function (routeInstance) {
      //titleを設定する処理
      if (routeInstance.meta.title) {
        var setTitle = routeInstance.meta.title + " | WellCom";
        document.title = setTitle;
      } else {
        document.title = "WellCom -健康経営フィードバックシートポータルサイト-";
      }
    },
  },
  mounted: function () {
    var routeInstance = this.$route;
    this.createTitleDesc(routeInstance);
  },
};
