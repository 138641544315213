<template>
  <div class="card-list">
    <!-- メインカード -->
    <div class="card-container mb-4">
      <v-card class="mx-auto card-content" max-width="100%" flat>
        <v-card-item>
          <div class="mb-3" style="font-weight: bold;">
            『{{ mainCard.title }}』
          </div>
          <div class="content-section">
            <template v-for="(content, index) in mainCardContents" :key="index">
              <template v-if="content.subtitle && content.text">
                <p class="content-label">{{ content.subtitle }}</p>
                <p class="content-text">{{ content.text }}</p>
                <br v-if="index < mainCardContents.length - 1" />
              </template>
            </template>
            <!-- KPI指標テーブル -->
            <template v-if="mainCard.kpi && mainCard.kpi_current && mainCard.kpi_target">
              <br />
              <p class="content-label">課題解決または効果に繋がるKPI</p>
              <v-table class="kpi-table mt-2">
                <tbody>
                  <tr>
                    <td class="kpi-label"><p>KPIの概要</p></td>
                    <td colspan="2"><p>{{ mainCard.kpi }}</p></td>
                  </tr>
                  <tr>
                    <td class="kpi-label"><p>KPIの値</p></td>
                    <td class="kpi-value">
                      <div class="kpi-value-label"><p>現状値</p></div>
                      <div><p>{{ mainCard.kpi_current }}</p></div>
                    </td>
                    <td class="kpi-value">
                      <div class="kpi-value-label"><p>目標値</p></div>
                      <div><p>{{ mainCard.kpi_target }}</p></div>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </template>
          </div>
        </v-card-item>
      </v-card>
    </div>

    <!-- 参照カード -->
    <div v-if="referenceCards && referenceCards.length > 0">
      <h4 class="reference-title mb-4">{{ referenceTitle }}</h4>
      <div
        v-for="(card, cardIndex) in referenceCards"
        :key="cardIndex"
        class="card-container mb-4"
      >
        <v-card class="mx-auto card-content" max-width="100%" flat>
          <v-card-item>
            <div class="company-info mb-3">
              <div class="company-title">{{ card.corporate_name }}</div>
              <div class="company-meta">
                <span class="text-caption">{{ card.industry }}</span>
                <span class="text-caption">総合偏差値：{{ card.score }}</span>
              </div>
            </div>
            <div class="content-section">
              <template v-for="(content, index) in getCardContents(card)" :key="index">
                <template v-if="content.subtitle && content.text">
                  <p class="content-label">{{ content.subtitle }}</p>
                  <p class="content-text">{{ content.text }}</p>
                  <br v-if="index < getCardContents(card).length - 1" />
                </template>
              </template>
              <!-- 参照カードのKPI指標テーブル -->
              <template v-if="card.kpi && card.kpi_current && card.kpi_target">
                <br />
                <p class="content-label">課題解決または効果に繋がるKPI</p>
                <v-table class="kpi-table mt-2">
                  <tbody>
                    <tr>
                      <td class="kpi-label"><p>KPIの概要</p></td>
                      <td colspan="2"><p>{{ card.kpi }}</p></td>
                    </tr>
                    <tr>
                      <td class="kpi-label"><p>KPIの値</p></td>
                      <td class="kpi-value">
                        <div class="kpi-value-label"><p>現状値</p></div>
                        <div><p>{{ card.kpi_current }}</p></div>
                      </td>
                      <td class="kpi-value">
                        <div class="kpi-value-label"><p>目標値</p></div>
                        <div><p>{{ card.kpi_target }}</p></div>
                      </td>
                    </tr>
                  </tbody>
                </v-table>
              </template>
            </div>
          </v-card-item>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.css"></style>
