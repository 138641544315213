export default {
  props: {
    rankText: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      icon: null,
      color: null
    }
  },
  watch: {
    rankText: {
      handler: 'setRankIcon',
      immediate: true
    }
  },
  methods: {
    setRankIcon() {
      const rankText = this.rankText;
      if (rankText === "1～50位") {
        this.icon = 'mdi-crown';
        this.color = '#FFC400';
      } else if (rankText === "51～100位") {
        this.icon = 'mdi-crown';
        this.color = '#B0BEC5';
      } else if (
        rankText === "101～150位" ||
        rankText === "151～200位" ||
        rankText === "201～250位" ||
        rankText === "251～300位"
      ) {
        this.icon = 'mdi-crown';
        this.color = '#795548';
      } else {
        this.icon = null;
        this.color = null;
      }
    }
  }
};
