<template>
  <v-row>
    <v-col>
      <h2>① 健康経営度評価結果</h2>
      <h3>
        ■ 総合順位：
        <span style="color: #b71c1c; font-size: 1.5rem">
          {{ companyInfo.overall_rank }}
          <RankIcon :rankText="companyInfo.overall_rank" />
          / {{ num_of_company }}社中
        </span>
      </h3>
      <h3>
        ■ 評価結果：
        <span style="color: #b71c1c; font-size: 1.5rem">
          {{ companyInfo.overall_deviation_value }}
        </span>
        <span style="color: #b71c1c; font-size: 1rem">
          （<span
            v-if="
              companyInfo.overall_deviation_value -
                companyInfo.last_year_overall_deviation_value >
              0
            "
            >↑</span
          ><span v-else>↓</span>
          {{
            Math.round(
              (companyInfo.overall_deviation_value -
                companyInfo.last_year_overall_deviation_value) *
                10
            ) / 10
          }}）前回偏差値 {{ companyInfo.last_year_overall_deviation_value }}
        </span>
      </h3>
    </v-col> </v-row
  ><br /><br />
  <v-row>
    <v-col cols="12">
      <h2>② 評価の内訳</h2>
    </v-col>
    <v-col cols="10">
      <v-table>
        <thead>
          <tr>
            <th class="text-left" width="45%">側面</th>
            <th class="text-left" width="15%">重み</th>
            <th class="text-left" width="20%"><b>貴社</b></th>
            <th class="text-left" width="20%">業界平均</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in assessment_item_list"
            :key="item.name"
            @click="toggleRow(item.name, index)"
            style="cursor: pointer"
          >
            <th>
              <template v-if="item.questions">
                <div class="flex-container">
                  <span>{{ item.name }}</span>
                  <QuestionDialog
                    :selectedQuestionList="item.questions.split('、')"
                    :modalTitle="
                      '【' +
                      item.name.replace(' -', '') +
                      '】に対応した調査票の設問'
                    "
                  />
                </div>
              </template>
              <template v-else>
                {{ item.name }}<span v-if="item.name !== '総合評価'"> ▼</span>
              </template>
            </th>
            <td>{{ item.weight }}</td>
            <td>
              <b>{{ item.companyRank }}</b>
            </td>
            <td>{{ item.avarageRankIndustory }}</td>
          </tr>
        </tbody>
      </v-table>
      <p>
        ※各側⾯の数値は、回答法⼈全体の平均に基づく偏差値です。各側⾯の数値に重みを掛けた値を合算して、得点・順位を算出しています。<br />
      </p>
    </v-col> </v-row
  ><br /><br />
  <v-row>
    <v-col cols="12">
      <h2>③ 評価の変遷（直近5回の評価結果）</h2>
    </v-col>
    <v-col cols="12">
      <BaseTable :headers="headers" :data="companyRankHistory" />
    </v-col>
    <v-dialog v-model="dialog" max-width="1000">
      <!-- ポップアップの内容 -->
      <v-card>
        <v-toolbar color="blue-grey">
          <v-toolbar-title>{{ this.modalTitle }}</v-toolbar-title>
        </v-toolbar>
        <div>
          <v-tabs v-model="questionTab">
            <v-tab
              v-for="(tabQuestion, index) in selectedQuestion.list"
              :key="tabQuestion.name"
              @click="changeQuestionImg(this.selectedQuestion.list[index])"
            >
              {{ this.selectedQuestion.list[index] }}
            </v-tab>
          </v-tabs>
        </div>
        <v-tabs-items v-model="questionTab">
          <v-tabs-items :value="this.selectedQuestion.text">
            <v-card-text>
              <b>{{ this.questionDetail }}</b>
            </v-card-text>
            <img
              :src="this.imageSrc"
              style="width: 100%"
              alt="Question Image"
            />
          </v-tabs-items>
        </v-tabs-items>
      </v-card>
    </v-dialog>
  </v-row>
  <br /><br />
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
