import AnnotationShokubaLab from "@/components/atoms/annotation-shokuba-lab/AnnotationShokubaLab.vue";
import BaseTable from "@/components/molecules/base-table/BaseTable.vue";
import { fetchCpmpanyWorkStyleInfo } from "@/services/api/companyInfoService";

export default {
  props: ["companyInfo"],
  components: {
    BaseTable,
    AnnotationShokubaLab,
  },
  data: () => ({
    tab: null,
    workStyleInfo: [],
    recruitInfo: [],
    headers: [
      { text: "項目", width: "40%" },
      { text: "登録情報", value: "value", width: "60%" },
    ],
  }),
  methods: {},
  mounted() {
    fetchCpmpanyWorkStyleInfo(this.companyInfo.code)
      .then((response) => {
        const responseData = response.data;

        // 採用情報の加工
        const recruitInfoKeys = [
          "新卒者の採用・定着状況(前年度/2年度前/3年度前)-男性",
          "新卒者の採用・定着状況(前年度/2年度前/3年度前)-女性",
          "新卒者の採用・定着状況(前年度/2年度前/3年度前)-男女計",
          "新卒者の採用・定着状況(前年度/2年度前/3年度前)-離職者数",
          "新卒者等以外（35歳未満）の採用・定着状況(前年度/2年度前/3年度前)-男性",
          "新卒者等以外（35歳未満）の採用・定着状況(前年度/2年度前/3年度前)-女性",
          "新卒者等以外（35歳未満）の採用・定着状況(前年度/2年度前/3年度前)-男女計",
          "新卒者等以外（35歳未満）の採用・定着状況(前年度/2年度前/3年度前)-離職者数",
          "再雇用の実績-男性",
          "再雇用の実績-女性",
          "再雇用の実績-注記",
          "中途採用の実績-男性",
          "中途採用の実績-女性",
          "中途採用の実績-注記",
        ];
        this.recruitInfo = processRecruitInfo(responseData, recruitInfoKeys);

        // 勤務実態情報の加工
        const workStyleInfoKeys = [
          "男女の平均継続勤務年数の差異-範囲（一覧）",
          "男女の平均継続勤務年数の差異-男性（一覧）",
          "男女の平均継続勤務年数の差異-女性（一覧）",
          "男女の平均継続勤務年数の差異-差異（一覧）",
          "男女の平均継続勤務年数の差異-注記(一覧）",
          "正社員の平均継続勤務年数",
          "従業員の平均年齢",
          "対象労働者全体の月平均の法定時間外労働時間と法定休日労働時間の合計-範囲（詳細）",
          "対象労働者全体の月平均の法定時間外労働時間と法定休日労働時間の合計-平均残業時間（詳細）",
          "対象労働者全体の月平均の法定時間外労働時間と法定休日労働時間の合計-取組内容（詳細）",
          "対象労働者全体の月平均の法定時間外労働時間と法定休日労働時間の合計-注記（詳細）",
          "平均の法定時間外労働60時間以上の労働者の数",
          "男女の賃金の差異-全労働者",
          "男女の賃金の差異-うち正規雇用労働者",
          "男女の賃金の差異-うち非正規雇用労働者",
          "男女の賃金の差異-対象期間",
          "男女の賃金の差異-注釈・説明",
          "対象の労働者全体の有給休暇取得率-範囲（一覧）",
          "対象の労働者全体の有給休暇取得率-取得率（一覧）",
          "対象の労働者全体の有給休暇取得率-注記(一覧)",
          "労働者に占める女性労働者の割合-範囲（一覧）",
          "労働者に占める女性労働者の割合-女性（一覧）",
          "労働者に占める女性労働者の割合-注記（一覧）",
          "育児休業対象者数（男性）",
          "育児休業対象者数（女性）",
          "育児休業取得者数（男性）",
          "育児休業取得者数（女性）",
          "育児休業取得率（男性）-男性取得率（一覧）",
          "育児休業取得率（男性）-注記（一覧）",
          "育児休業取得率（女性）-女性取得率（一覧）",
          "育児休業取得率（女性）-注記（一覧）",
          "育児目的休暇制度の具体的内容",
          "短時間正社員制度、在宅勤務、テレワークその他の働き方の見直しに資する多様な労働条件の整備のための措置の内容",
          "育休・育児を行う女性労働者の能力向上・キャリア形成支援のための取組に係る計画の内容",
          "育休・育児を行う女性労働者の能力向上・キャリア形成支援のための取組に係る計画の内容の実施状況",
          "女性活躍推進法に基づく一般事業主行動計画-内容",
          "女性活躍推進法に基づく一般事業主行動計画-リンクURL1",
          "女性活躍推進法に基づく一般事業主行動計画-リンクURL2",
          "女性活躍推進法に基づく一般事業主行動計画-リンクURL3",
          "次世代育成支援対策推進法に基づく一般事業主行動計画-内容",
          "次世代育成支援対策推進法に基づく一般事業主行動計画-リンクURL1",
          "次世代育成支援対策推進法に基づく一般事業主行動計画-リンクURL2",
          "次世代育成支援対策推進法に基づく一般事業主行動計画-リンクURL3",
          "両立支援の取組事例-現在実施中又は実施していた取組・実績など＜育児休業関係＞",
          "両立支援の取組事例-現在実施中又は実施していた取組・実績など＜仕事と介護の両立に関する取組＞",
          "女性労働者に対する職業生活に関する機会の提供に資する社内制度の概要",
          "労働者の職業生活と家庭生活の両立に資する社内制度の概要",
          "自由記述欄",
          "自由ＰＲ欄"
        ];
        this.workStyleInfo = processWorkStyleInfo(responseData, workStyleInfoKeys);
      })
      .catch((error) => {
        console.error("しょくばらぼ情報の取得に失敗しました:", error);
      });

    /**
     * @param {Object} data 加工前のデータ（採用情報）
     * @param {Array} recruitInfoKeys 採用情報のキー
     * @returns {Array} 加工後のデータ（採用情報）
     */
    function processRecruitInfo(data, recruitInfoKeys) {
      return Object.entries(data).reduce((result, [key, value]) => {
        if (!key || !value || !recruitInfoKeys.includes(key)) return result;

        const [category, type] = key.split('-');
        const prefix = type === "男性" || type === "女性" ? `${type}：` : '※';
        const newValue = `${prefix}${value}`;

        const existingItem = result.find(item => item.name === category);
        if (existingItem) {
          existingItem.value += type === "男性" || type === "女性" ? `、${newValue}` : `<br>${newValue}`;
        } else {
          result.push({ name: category, value: newValue });
        }

        return result;
      }, []);
    }

    /**
     * @param {Object} data 加工前のデータ（勤務実態情報）
     * @param {Array} workStyleInfoKeys 勤務実態情報のキー
     * @returns {Array} 加工後のデータ（勤務実態情報）
     */
    function processWorkStyleInfo(data, workStyleInfoKeys) {
      // まとめる項目
      const keysToProcess = [
        "男女の平均継続勤務年数の差異",
        "対象労働者全体の月平均の法定時間外労働時間と法定休日労働時間の合計",
        "対象の労働者全体の有給休暇取得率",
        "労働者に占める女性労働者の割合",
        "男女の賃金の差異",
        "女性活躍推進法に基づく一般事業主行動計画",
        "次世代育成支援対策推進法に基づく一般事業主行動計画",
      ];
      // そのまま表示する項目
      const additionalKeys = [
        "正社員の平均継続勤務年数",
        "従業員の平均年齢",
        "平均の法定時間外労働60時間以上の労働者の数",
        "育児休業対象者数（男性）",
        "育児休業対象者数（女性）",
        "育児休業取得者数（男性）",
        "育児休業取得者数（女性）",
        "育児休業取得率（男性）-男性取得率（一覧）",
        "育児休業取得率（男性）-注記（一覧）",
        "育児休業取得率（女性）-女性取得率（一覧）",
        "育児休業取得率（女性）-注記（一覧）",
        "育休・育児を行う女性労働者の能力向上・キャリア形成支援のための取組に係る計画の内容",
        "育休・育児を行う女性労働者の能力向上・キャリア形成支援のための取組に係る計画の内容の実施状況",
        "育児目的休暇制度の具体的内容",
        "短時間正社員制度、在宅勤務、テレワークその他の働き方の見直しに資する多様な労働条件の整備のための措置の内容",
        "両立支援の取組事例-現在実施中又は実施していた取組・実績など＜育児休業関係＞",
        "両立支援の取組事例-現在実施中又は実施していた取組・実績など＜仕事と介護の両立に関する取組＞",
        "女性労働者に対する職業生活に関する機会の提供に資する社内制度の概要",
        "労働者の職業生活と家庭生活の両立に資する社内制度の概要",
        "自由記述欄",
        "自由ＰＲ欄"
      ];

      return Object.entries(data).reduce((result, [key, value]) => {
        if (!key || !value || !workStyleInfoKeys.includes(key)) return result;

        let cleanedValue = value.replace(/^\d+:/, '').trim();
        cleanedValue = cleanedValue.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');
        const processKey = keysToProcess.find(baseKey => key.startsWith(baseKey));

        if (processKey) {
          const existingItem = result.find(item => item.name === processKey);
          let newValue = key.includes("注記") || key.includes("注釈") 
            ? `※${cleanedValue}`
            : `【${key.split('-')[1]}】<br>${cleanedValue}`;

          if (existingItem) {
            existingItem.value += `<br>${newValue}`;
          } else {
            result.push({ name: processKey, value: newValue });
          }
        } else if (additionalKeys.includes(key)) {
          result.push({ name: key, value });
        }

        return result;
      }, []);
    }
  },
};
