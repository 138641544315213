import RankIcon from "@/components/atoms/icons/rank-icon/RankIcon.vue";

export default {
  name: "BaseTable",
  components: {
    RankIcon
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
};
