<template>
  <v-dialog v-model="showDialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>
        <span class="dialog-title">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog" class="close-btn">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="dialog-content">
        <slot></slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
