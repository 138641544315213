export default {
  name: 'CardList',
  props: {
    mainCard: {
      type: Object,
      required: true,
      validator: (obj) => {
        const requiredProps = ['title'];
        const hasRequiredProps = requiredProps.every(prop => prop in obj);
        const dynamicProps = Object.keys(obj).every(key => {
          return (
            key === 'title' ||
            key.startsWith('subtitle') ||
            key.startsWith('text') ||
            ['kpi', 'kpi_current', 'kpi_target'].includes(key)
          );
        });
        return hasRequiredProps && dynamicProps;
      }
    },
    referenceTitle: {
      type: String,
      default: ''
    },
    referenceCards: {
      type: Array,
      default: () => [],
      validator: (arr) => {
        return arr.every(item => {
          const requiredProps = ['corporate_name', 'industry', 'score'];
          const hasRequiredProps = requiredProps.every(prop => prop in item);
          const dynamicProps = Object.keys(item).every(key => {
            return (
              requiredProps.includes(key) ||
              key.startsWith('subtitle') ||
              key.startsWith('text') ||
              ['kpi', 'kpi_current', 'kpi_target'].includes(key)
            );
          });
          return hasRequiredProps && dynamicProps;
        });
      }
    }
  },
  computed: {
    mainCardContents() {
      const contents = [];
      const keys = Object.keys(this.mainCard);
      const subtitleKeys = keys.filter(key => key.startsWith('subtitle'));
      subtitleKeys.forEach(subtitleKey => {
        const index = subtitleKey.replace('subtitle', '');
        const textKey = `text${index}`;
        if (this.mainCard[subtitleKey] && this.mainCard[textKey]) {
          contents.push({
            subtitle: this.mainCard[subtitleKey],
            text: this.mainCard[textKey]
          });
        }
      });
      return contents;
    }
  },
  methods: {
    getCardContents(card) {
      const contents = [];
      const keys = Object.keys(card);
      const subtitleKeys = keys.filter(key => key.startsWith('subtitle'));
      subtitleKeys.forEach(subtitleKey => {
        const index = subtitleKey.replace('subtitle', '');
        const textKey = `text${index}`;
        if (card[subtitleKey] && card[textKey]) {
          contents.push({
            subtitle: card[subtitleKey],
            text: card[textKey]
          });
        }
      });
      return contents;
    }
  }
}
