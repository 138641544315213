<template>
  <div>
    <div class="title">
      <v-row align="center" no-gutters style="height: 100px">
        <v-col cols="12">
          <v-sheet>
            <h1>{{ companyInfo.corporate_name }}</h1>
            <p>{{ companyInfo.insurer_name }}</p>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider
        :thickness="3"
        class="border-opacity-75"
        color="var(--main-green)"
      ></v-divider>
      <div class="my-3">
        <CompanyChips :companyInfo="companyInfo" />
      </div>
    </div>
    <div style="text-align: left" class="mx-4 my-4">
      <DownloadButton
        :clickHandler="openPDF"
        buttonText="フィードバックシートを見る"
      />
    </div>
    <div style="text-align: left" class="my-4">
      <v-col cols="12">
        <BaseTable :headers="headers" :data="companyBasicInfo" />
        <AnnotationShokubaLab />
      </v-col>
    </div>
    <div class="companyInfo">
      <v-card flat>
        <v-row>
          <v-col cols="12">
            <v-tabs v-model="tab" color="var(--main-green)" align-tabs="center" grow>
              <v-tab
                :value="1"
                style="font-size: large"
                prepend-icon="mdi-chart-bar"
                >評価結果</v-tab
              >
              <v-tab
                :value="2"
                style="font-size: large"
                prepend-icon="mdi-clipboard-outline"
                >健康経営の戦略</v-tab
              >
              <v-tab
                :value="3"
                style="font-size: large"
                prepend-icon="mdi-timelapse"
                >行動指標</v-tab
              >
              <v-tab
                :value="4"
                style="font-size: large"
                prepend-icon="mdi-checkbox-multiple-marked-outline"
                >課題への対応</v-tab
              >
              <v-tab
                :value="5"
                style="font-size: large"
                prepend-icon="mdi-account-multiple-outline"
                class="external-tab"
                :color="'var(--sub-orange)'"
                >職場情報</v-tab
              >
            </v-tabs>
          </v-col>
        </v-row>
        <v-window v-model="tab">
          <v-window-item v-for="n in 5" :key="n" :value="n">
            <v-container fluid v-if="n === 1">
              <!-- タブ１：評価結果 -->
              <CompanyInfoTab1 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 2">
              <!-- タブ２：健康経営の戦略 -->
              <CompanyInfoTab2 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 3">
              <!-- タブ３：行動指標 -->
              <CompanyInfoTab3 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 4">
              <!-- タブ４：課題への対応 -->
              <CompanyInfoTab4 :companyInfo="companyInfo" />
            </v-container>
            <v-container fluid v-if="n === 5">
              <!-- タブ5：しょくばらぼ情報 -->
              <CompanyInfoTab5 :companyInfo="companyInfo" />
            </v-container>
          </v-window-item>
        </v-window>
      </v-card>
    </div>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
