<template>
  <div>
    <button class="question" @click="showQuestion(selectedQuestionList)">
      <p class="question-button-label">対応Q番号</p>
    </button>
    <v-dialog v-model="dialog" max-width="1000">
      <!-- ポップアップの内容 -->
      <v-card class="dialog-card">
        <div class="dialog-header">
          <v-toolbar color="var(--main-green)" class="dialog-toolbar">
            <v-toolbar-title class="text-white">{{ modalTitle }}</v-toolbar-title>
          </v-toolbar>
          <div class="dialog-tabs">
            <v-tabs v-model="questionTab" color="var(--main-green)">
              <v-tab
                v-for="(tabQuestion, index) in selectedQuestion.list"
                :key="index"
                @click="changeQuestionImg(tabQuestion)"
              >
                {{ tabQuestion }}
              </v-tab>
            </v-tabs>
          </div>
          <div class="question-detail">
            <b>{{ questionDetail }}</b>
          </div>
        </div>
        <div class="dialog-content">
          <v-tabs-items v-model="questionTab">
            <v-tabs-items :value="selectedQuestion.text">
              <img :src="imageSrc" style="width: 100%" alt="Question Image" />
            </v-tabs-items>
          </v-tabs-items>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
