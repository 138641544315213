<template>
  <CommonDialog
    :showDialog="showDialog"
    :title="title"
    @update:showDialog="closeDialog"
  >
    <v-list class="status-list">
      <v-list-item
        v-for="status in statusList"
        :key="status.code"
        @click="selectStatus(status.code, status.name)"
        class="status-list-item"
      >
        <v-list-item-content class="status-list-item-content">
          <span class="status-name"><b>{{ status.name }}</b></span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CommonDialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
