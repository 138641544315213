<template>
  <v-row>
    <v-col cols="12">
      <h2>⑦ 経営レベルの会議での議題化</h2>
    </v-col>
    <v-col cols="10">
      <h3>
        ■
        全社における健康経営の推進に関して、経営レベルの会議（取締役会や経営会議等）で議題にしている内容
      </h3>
      <p>＜a. 取締役会＞</p>
      <BaseTable :headers="meeting_agenda_header" :data="board_meeting_agenda" />
      <p>＜b. 取締役会以外の経営レベルの会議（経営会議等）＞</p>
      <BaseTable :headers="meeting_agenda_header" :data="management_meeting_agenda" />
      <br /><br />
      <h3>■ 2023年度の会議⾃体の総実施回数と健康経営の推進を議題にした回数</h3>
      <BaseTable :headers="times_on_agenda_header" :data="times_on_agenda" />
    </v-col> </v-row
  ><br /><br />
  <v-row>
    <v-col cols="12">
      <h2>⑧ 各種施策の2023年度の参加率</h2>
    </v-col>
    <v-col cols="10">
      <template v-for="item in various_measures" :key="item.name">
        <h3 v-if="item.category !== ''">■ {{ item.category }}</h3>
        <p v-if="item.section !== ''">＜{{ item.section }}の主な取り組み＞</p>
        <v-table>
          <tbody>
            <tr v-if="item.type === 2">
              <th class="text-left" width="20%">取組概要</th>
              <th class="text-left" width="30%" colspan="3">
                {{ item.summary }}
              </th>
            </tr>
            <tr v-if="item.type === 2">
              <th class="text-left" width="20%">取組内容</th>
              <th class="text-left" width="30%" colspan="3">{{ item.body }}</th>
            </tr>
            <tr v-if="item.type !== 3 && item.type !== 4">
              <th class="text-left" width="20%">
                (a)対象者の比率<br />※全従業員に占める対象者の割合
              </th>
              <th class="text-left" width="30%">{{ item.aRate || 'ー' }} ％</th>
              <th class="text-left" width="20%">
                (b)参加者の比率<br />※対象者に占める参加者の割合
              </th>
              <th class="text-left" width="30%">{{ item.bRate || 'ー' }} ％</th>
            </tr>
          </tbody>
        </v-table>
        <v-table v-if="item.type === 3">
          <tbody>
            <tr>
              <th class="text-left" width="10%"></th>
              <th class="text-left" width="10%">実施</th>
              <th class="text-left" width="20%" colspan="2">参加率</th>
            </tr>
            <tr>
              <td class="text-left">女性限定</td>
              <td class="text-left">{{ item.aFlag }}</td>
              <td class="text-left">全女性従業員の</td>
              <td class="text-left">{{ item.aRate || 'ー' }} ％</td>
            </tr>
            <tr>
              <td class="text-left">管理職限定</td>
              <td class="text-left">{{ item.bFlag }}</td>
              <td class="text-left">全管理職の</td>
              <td class="text-left">{{ item.bRate || 'ー' }} ％</td>
            </tr>
            <tr>
              <td class="text-left">限定しない</td>
              <td class="text-left">{{ item.cFlag }}</td>
              <td class="text-left">全従業員の</td>
              <td class="text-left">{{ item.cRate || 'ー' }} ％</td>
            </tr>
          </tbody>
        </v-table>
        <v-table v-if="item.type === 4">
          <tbody>
            <tr>
              <th class="text-left" width="10%"></th>
              <th class="text-left" width="10%">実施</th>
              <th class="text-left" width="20%" colspan="2">参加率</th>
            </tr>
            <tr>
              <td class="text-left">管理職への男性育児休業研修</td>
              <td class="text-left">{{ item.aFlag }}</td>
              <td class="text-left">全管理職の</td>
              <td class="text-left">{{ item.aRate || 'ー' }} ％</td>
            </tr>
            <tr>
              <td class="text-left">従業員への介護研修</td>
              <td class="text-left">{{ item.bFlag }}</td>
              <td class="text-left">全従業員の</td>
              <td class="text-left">{{ item.bRate || 'ー' }} ％</td>
            </tr>
            <tr>
              <td class="text-left">管理職への介護研修</td>
              <td class="text-left">{{ item.cFlag }}</td>
              <td class="text-left">全管理職の</td>
              <td class="text-left">{{ item.cRate || 'ー' }} ％</td>
            </tr>
          </tbody>
        </v-table>
        <br /><br />
      </template>
    </v-col> </v-row
  ><br /><br />
</template>
<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
