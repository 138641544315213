<template>
  <v-row>
    <v-col cols="12">
      <h2>⑤ 健康経営の戦略</h2>
    </v-col>
    <v-row>
      <v-col cols="12">
        <template v-if="desserts5[0].choices_content !== null">
          <h3>■ 経営上の課題と期待する効果</h3>
          <v-select
            v-model="selectedThemeIndex"
            :items="[
              { title: desserts5[0].choices_content, value: 0 },
              { title: desserts5[1].choices_content, value: 1 }
            ]"
            item-title="title"
            item-value="value"
            label="課題のテーマを選択"
            class="theme-select mb-4"
            hide-details
            variant="outlined"
            color="var(--main-green)"
            bg-color="white"
            base-color="var(--main-green)"
            :focused="true"
          ></v-select>
          <CardList
            :mainCard="{
              title: desserts5[selectedThemeIndex].choices_content,
              subtitle1: '健康経営で解決したい経営上の課題',
              text1: desserts5[selectedThemeIndex].issue_content,
              subtitle2: '健康経営の実施により期待する効果',
              text2: desserts5[selectedThemeIndex].content_effect,
              kpi: desserts5[selectedThemeIndex].issue_content_kpi,
              kpi_current: desserts5[selectedThemeIndex].issue_content_kpi_current,
              kpi_target: desserts5[selectedThemeIndex].issue_content_kpi_target
            }"
            referenceTitle="同じ課題のテーマを選んだ企業の回答例"
            :referenceCards="
              getReferenceCards(selectedThemeIndex).map((item) => ({
                corporate_name: item.corporate_name,
                score: item.score,
                industry: item.industry,
                subtitle1: '健康経営で解決したい経営上の課題',
                text1: item.issue_content,
                subtitle2: '健康経営の実施により期待する効果',
                text2: item.content_effect,
                kpi: item.issue_content_kpi,
                kpi_current: item.issue_content_kpi_current,
                kpi_target: item.issue_content_kpi_target
              }))
            "
          />
        </template>
      </v-col>
    </v-row> </v-row
  ><br /><br />
  <v-row>
    <v-col cols="12">
      <h2>⑥ 健康経営の情報開示</h2>
    </v-col>
    <v-col cols="12">
      <h3>■ 情報開示媒体</h3>
      <v-table>
        <thead>
          <tr>
            <th class="text-left" width="20%">媒体の種別</th>
            <th class="text-left" width="30%">
              {{ desserts6[0].health_management_media_choices_content }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th class="text-left">URL</th>
            <td class="text-left">
              <a :href="desserts6[0].health_management_URL" target="_blank">{{
                desserts6[0].health_management_URL
              }}</a>
            </td>
          </tr>
        </tbody>
      </v-table>
      <h3>■ 従業員パフォーマンス指標の開示</h3>
      <v-table>
        <tbody>
          <tr>
            <th class="text-left" width="10%">a.アブセンティーズム</th>
            <th class="text-left" width="10%">実績値</th>
            <th class="text-left" width="10%">測定方法</th>
            <th class="text-left" width="10%">複数年度分の結果</th>
            <th class="text-left" width="10%">測定範囲および回答率</th>
            <th class="text-left" width="10%">URL</th>
          </tr>
          <tr>
            <td class="text-left"></td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_a_1 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_a_2 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_a_3 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_a_4 }}
            </td>
            <td class="text-left">
              <a
                :href="desserts6[0].employee_performance_info_a_URL"
                target="_blank"
                >{{ desserts6[0].employee_performance_info_a_URL }}</a
              >
            </td>
          </tr>
          <tr>
            <th class="text-left" width="10%">b.プレゼンティーズム</th>
            <th class="text-left" width="10%">実績値</th>
            <th class="text-left" width="10%">測定方法</th>
            <th class="text-left" width="10%">複数年度分の結果</th>
            <th class="text-left" width="10%">測定範囲および回答率</th>
            <th class="text-left" width="10%">URL</th>
          </tr>
          <tr>
            <td class="text-left"></td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_b_1 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_b_2 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_b_3 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_b_4 }}
            </td>
            <td class="text-left">
              <a
                :href="desserts6[0].employee_performance_info_b_URL"
                target="_blank"
                >{{ desserts6[0].employee_performance_info_b_URL }}</a
              >
            </td>
          </tr>
          <tr>
            <th class="text-left" width="10%">c.ワークエンゲージメント</th>
            <th class="text-left" width="10%">実績値</th>
            <th class="text-left" width="10%">測定方法</th>
            <th class="text-left" width="10%">複数年度分の結果</th>
            <th class="text-left" width="10%">測定範囲および回答率</th>
            <th class="text-left" width="10%">URL</th>
          </tr>
          <tr>
            <td class="text-left"></td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_c_1 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_c_2 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_c_3 }}
            </td>
            <td class="text-left">
              {{ desserts6[0].employee_performance_info_c_4 }}
            </td>
            <td class="text-left">
              <a
                :href="desserts6[0].employee_performance_info_c_URL"
                target="_blank"
                >{{ desserts6[0].employee_performance_info_c_URL }}</a
              >
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-col> </v-row
  ><br /><br />
</template>
<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
