import CommonDialog from '@/components/organisms/dialog/CommonDialog.vue';

export default {
  name: 'StatusNarrowdownDialog',
  components: {
    CommonDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    selectedStatus: {
      type: String
    }
  },
  data() {
    return {
      title: "顕彰を選択してください",
      statusList: [
        {
          code: "brand",
          name: "健康経営銘柄"
        },
        {
          code: "white500",
          name: "ホワイト500"
        },
        {
          code: "big_good",
          name: "優良法人（大）"
        },
        {
          code: "bright500",
          name: "ブライト500"
        },
        {
          code: "next_bright1000",
          name: "ネクストブライト1000"
        },
        {
          code: "small_good",
          name: "優良法人（中小）"
        }
      ],
      selectedStatus: "",
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
    selectStatus(statusCode, statusName) {
      this.$emit('update:selectedStatusCode', statusCode);
      this.$emit('update:selectedStatus', statusName);
      this.closeDialog();
    },
  },
};
