import { createApp } from "vue";
import App from "./App/App.vue";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import router from "./router";
import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";

// Google Analyticsの設定
function gtag() {
  window.dataLayer.push(arguments);
}

router.afterEach((to) => {
  gtag("config", "G-WHDQ5CB8KD", {
    page_path: to.fullPath,
  });
});

loadFonts();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(FloatingVue, {
    themes: {
      "custom-tooltip": {
        $extend: "tooltip",
        triggers: ["hover"],
        autoHide: true,
        placement: "top",
        html: true,
        autoSize: "max",
      },
    },
  })
  .mount("#app");
