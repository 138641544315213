import {
  fetchAllCompanies,
  searchCompany,
  searchCompanyByHealthManagement,
  searchCompanyByHealthMeasure
} from "@/services/api/companyInfoService";
import {
  HEALTH_MANAGEMENT_LIST,
  HEALTH_MEASURE_LIST,
  ALL_NUM_OF_COMPANY_COUNT,
  NUM_OF_COMPANY_COUNT,
  NUM_OF_COMPANY_COUNT_THAT_PUBLISH_SHEETS
} from "@/config/const";
import RankIcon from "@/components/atoms/icons/rank-icon/RankIcon.vue";
import CompanyChips from "@/components/molecules/company-chips/CompanyChips.vue";
import IndustryNarrowdownDialog from "@/components/organisms/dialog/narrowdown-dialog/industry-narrowdown-dialog/IndustryNarrowdownDialog.vue";
import InsurerNarrowdownDialog from "@/components/organisms/dialog/narrowdown-dialog/insurer-narrowdown-dialog/InsurerNarrowdownDialog.vue";
import StatusNarrowdownDialog from "@/components/organisms/dialog/narrowdown-dialog/status-narrowdown-dialog/StatusNarrowdownDialog.vue";
import CompanySizeNarrowdownDialog from "@/components/organisms/dialog/narrowdown-dialog/company-size-narrowdown-dialog/CompanySizeNarrowdownDialog.vue";

export default {
  props: ["industryName", "industryCode", "insurerName"],
  components: {
    CompanyChips,
    IndustryNarrowdownDialog,
    InsurerNarrowdownDialog,
    StatusNarrowdownDialog,
    CompanySizeNarrowdownDialog,
    RankIcon
  },
  data: () => ({
    title: "",
    name: "",
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 10) || "Name must be less than 10 characters",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
    allCompanies: [],
    currentPage: 1,
    itemsPerPage: 9,
    searchText: "",
    searchPlaceholder: "企業名",
    totalPage: 0,
    isLoading: false,
    isLoadingError: false,
    activeTab: 0,
    tabs: ['企業名', '健康経営の戦略', '課題への対応'],
    selectedIndustryCode: 0,
    selectedIndustry: "",
    selectedStatusCode: "",
    selectedStatus: "",
    selectedInsurer: "",
    selectedCompanySize: "",
    selectedCompanySizeMin: "",
    selectedCompanySizeMax: "",
    healthManagementList: HEALTH_MANAGEMENT_LIST,
    selectedHealthManagementCode: "",
    selectedHealthManagement: null,
    healthMeasureList: HEALTH_MEASURE_LIST,
    selectedHealthMeasureCode: "",
    selectedHealthMeasure: [],
    showIndustryDialog: false,
    showInsurerDialog: false,
    showStatusDialog: false,
    showCompanySizeDialog: false,
    companyCount: 0,
    kpiContent: "",
    allNumOfCompanyCount: ALL_NUM_OF_COMPANY_COUNT,
    numOfCompanyCount: NUM_OF_COMPANY_COUNT,
    numOfCompanyCountThatPublishSheets: NUM_OF_COMPANY_COUNT_THAT_PUBLISH_SHEETS,
  }),
  computed: {
    totalPages() {
      this.updateTotalPage();
      return this.totalPage;
    },
    paginatedCompanies() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.allCompanies.slice(start, end);
    },
  },
  methods: {
    async validate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) alert("Form is valid");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    fetchData() {
      this.isLoading = true;
      fetchAllCompanies()
        .then((response) => {
          this.allCompanies = response.data;
          this.title = "全企業一覧（総合評価順）";
          this.currentPage = 1;
          this.companyCount = this.allCompanies.length;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.allCompanies = [];
          this.isLoadingError = true;
          this.isLoading = false;
        });
    },
    handleCompanyClick(company) {
      if (company.code != 0) {
        const url = this.$router.resolve({ name: "company", params: { code: company.code } }).href;
        window.open(url, '_blank');
      } else {
        console.log("この企業はクリックできません");
      }
    },
    updateTotalPage() {
      this.totalPage = Math.ceil(this.allCompanies.length / this.itemsPerPage);
    },
    searchCompanyByTextNameSearchBox() {
      this.isLoading = true;
      searchCompany(
        this.searchText, // companyName
        this.selectedIndustryCode, // industryCode
        this.selectedStatusCode, // companyStatus
        this.selectedInsurer, // insureName
        this.selectedCompanySizeMin, // employeeScaleMin
        this.selectedCompanySizeMax // employeeScaleMax
      )
        .then((response) => {
          if (response.data.error && response.data.error === "over_10000") {
            this.allCompanies = [];
            this.title = "検索結果が10000件を超えました。検索条件を絞り込んでください。";
            this.currentPage = 1;
            this.companyCount = 0;
            this.isLoading = false;
          }
          else {
            this.allCompanies = response.data;
            this.title = "企業検索結果（総合評価順）";
            this.currentPage = 1;
            this.companyCount = this.allCompanies.length;
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.isLoading = false;
        });
    },
    searchCompanyByHealthManagementSearchBox() {
      this.isLoading = true;
      searchCompanyByHealthManagement(
        this.selectedHealthManagementCode, // healthManagementIssueNum
        this.kpiContent, // KPIContent
        this.selectedIndustryCode, // industryCode
        this.selectedStatusCode, // companyStatus
        this.selectedCompanySizeMin, // employeeScaleMin
        this.selectedCompanySizeMax // employeeScaleMax
      )
        .then((response) => {
          this.allCompanies = response.data;
          this.title = "企業検索結果（総合評価順）";
          this.currentPage = 1;
          this.companyCount = this.allCompanies.length;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.isLoading = false;
        });
    },
    searchCompanyByHealthMeasureSearchBox() {
      this.isLoading = true;
      searchCompanyByHealthMeasure(
        this.selectedHealthMeasureCode, // healthMeasureNum
        this.selectedIndustryCode, // industryCode
        this.selectedStatusCode, // companyStatus
        this.selectedCompanySizeMin, // employeeScaleMin
        this.selectedCompanySizeMax // employeeScaleMax
      )
        .then((response) => {
          this.allCompanies = response.data;
          this.title = "企業検索結果（総合評価順）";
          this.currentPage = 1;
          this.companyCount = this.allCompanies.length;
          this.isLoading = false;
        })
        .catch((error) => {
          console.error("API呼び出しエラー", error);
          this.isLoading = false;
        });
    },
    clearSelectedIndustry() {
      this.selectedIndustryCode = 0;
      this.selectedIndustry = "";
    },
    clearSelectedInsurer() {
      this.selectedInsurer = "";
    },
    clearSelectedStatus() {
      this.selectedStatusCode = "";
      this.selectedStatus = "";
    },
    clearSelectedCompanySize() {
      this.selectedCompanySize = "";
      this.selectedCompanySizeMin = "";
      this.selectedCompanySizeMax = "";
    },
  },
  mounted() {
    if (this.insurerName !== null && this.insurerName !== undefined) {
      this.searchCompaniesByInsurer(this.insurerName);
    } else if (
      this.industryName !== "全業種" &&
      this.industryName !== null &&
      this.industryName !== undefined
    ) {
      this.searchCompaniesByIndustry(this.industryName, this.industryCode);
    } else {
      this.fetchData();
    }
  },
  watch: {
    selectedIndustryCode() {
      if (this.selectedHealthManagementCode) {
        this.searchCompanyByHealthManagementSearchBox();
      } else if (this.selectedHealthMeasureCode) {
        this.searchCompanyByHealthMeasureSearchBox();
      } else {
        this.searchCompanyByTextNameSearchBox();
      }
    },
    selectedInsurer() {
      this.searchCompanyByTextNameSearchBox();
    },
    selectedStatusCode() {
      if (this.selectedHealthManagementCode) {
        this.searchCompanyByHealthManagementSearchBox();
      } else if (this.selectedHealthMeasureCode) {
        this.searchCompanyByHealthMeasureSearchBox();
      } else {
        this.searchCompanyByTextNameSearchBox();
      }
    },
    selectedCompanySize() {
      if (this.selectedHealthManagementCode) {
        this.searchCompanyByHealthManagementSearchBox();
      } else if (this.selectedHealthMeasureCode) {
        this.searchCompanyByHealthMeasureSearchBox();
      } else {
        this.searchCompanyByTextNameSearchBox();
      }
    },
    selectedHealthManagement() {
      const index = this.healthManagementList.indexOf(this.selectedHealthManagement);
      this.selectedHealthManagementCode = index !== -1 ? index + 1 : "";
      this.searchCompanyByHealthManagementSearchBox();
    },
    selectedHealthMeasure() {
      const index = this.healthMeasureList.indexOf(this.selectedHealthMeasure);
      this.selectedHealthMeasureCode = index !== -1 ? index + 1 : "";
      this.searchCompanyByHealthMeasureSearchBox();
    },
    activeTab: {
      handler() {
        // タブ切り替え時に検索条件をリセット
        this.searchText = '';
        this.selectedHealthManagement = null;
        this.selectedHealthMeasure = null;
        this.kpiContent = '';
      },
      immediate: true
    }
  },
};
