<template>
  <CommonDialog
    :showDialog="showDialog"
    :title="title"
    @update:showDialog="closeDialog"
  >
    <v-list class="industry-list">
      <v-list-item
        v-for="(industry, index) in companyCountList"
        :key="index"
        @click="selectIndustry(index+1, industry.name)"
        class="industry-list-item"
      >
        <v-list-item-content class="industry-list-item-content">
          <span class="industry-name"><b>{{ industry.name }}</b></span>
          <!-- <span v-if="industry.count !== null" class="industry-count">
            ({{ industry.count }}社)
          </span> -->
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CommonDialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
