<template>
  <CommonDialog
    :showDialog="showDialog"
    :title="title"
    @update:showDialog="closeDialog"
  >
    <v-list class="company-size-list">
      <v-list-item
        v-for="(companySize, index) in companySizeList"
        :key="index"
        @click="selectCompanySize(companySize.min, companySize.max, companySize.label)"
        class="company-size-list-item"
      >
        <v-list-item-content class="company-size-list-item-content">
          <span class="company-size-name"><b>{{ companySize.label }}</b></span>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </CommonDialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
