<template>
  <div class="mb-10">
    <div class="title my-5">

      <!-- 検索ボックス -->
      <v-row align="center" no-gutters>
        <v-col cols="12">
          <v-sheet class="search-box">
            <v-tabs v-model="activeTab" class="search-tab" dark grow hide-slider>
              <v-tab
                v-for="(tab, index) in tabs"
                :key="index"
                :class="{'active-tab': activeTab === index}"
                class="tab"
              >
                <v-icon left :class="{'active-icon': activeTab === index}">mdi-magnify</v-icon>
                {{ tab }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="activeTab">
              <v-tab-item v-if="activeTab === 0" class="search-tab-content">
                <!-- 企業名での検索ボックス -->
                <v-form class="search-form" ref="searchForm" @submit.prevent="searchCompanyByTextNameSearchBox">
                  <p class="search-message">全掲載企業数 <span class="green-text">{{ allNumOfCompanyCount }}</span>社（うちフィードバックシート公開中企業数 <span class="green-text">{{ numOfCompanyCountThatPublishSheets }}</span>社）</p>
                  <v-row no-gutters class="search-text-row">
                    <v-col cols="10">
                      <v-text-field
                        v-model="searchText"
                        placeholder="企業名で探す"
                        variant="outlined"
                        class="search-text"
                        bg-color="white"
                        hide-details="auto"
                        density="comfortable"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn @click="searchCompanyByTextNameSearchBox" class="search-button" flat>
                        <v-icon left>mdi-magnify</v-icon>
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="2">
                      絞り込み
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showIndustryDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedIndustry}"
                            flat
                          >
                            業種
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedIndustry || '指定なし' }}
                            <span
                              v-if="selectedIndustry"
                              @click="clearSelectedIndustry"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="showStatusDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedStatus}"
                            flat
                          >
                            顕彰
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedStatus  || '指定なし' }}
                            <span
                              v-if="selectedStatus"
                              @click="clearSelectedStatus"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showCompanySizeDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedCompanySize}"
                            flat
                          >
                            従業員規模
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedCompanySize || '指定なし' }}
                            <span
                              v-if="selectedCompanySize"
                              @click="clearSelectedCompanySize"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="showInsurerDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedInsurer}"
                            flat
                          >
                            保険組合
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedInsurer || '指定なし' }}
                            <span
                              v-if="selectedInsurer"
                              @click="clearSelectedInsurer"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item v-if="activeTab === 1" class="search-tab-content">
                <!-- 健康経営の戦略での検索ボックス -->
                <v-form class="search-form" ref="searchFormHealthManagement" @submit.prevent="searchCompanyByHealthManagementSearchBox">
                  <v-row no-gutters>
                    <v-col cols="5">
                      <p class="search-message">健康経営で解決したい経営上の課題</p>
                    </v-col>
                    <v-col cols="5">
                      <p class="search-message">KPI</p>
                    </v-col>
                  </v-row>
                  <v-row no-gutters class="search-text-row">
                    <v-col cols="5">
                      <v-select
                        v-model="selectedHealthManagement"
                        :items="healthManagementList"
                        placeholder="健康経営の戦略で探す"
                        variant="outlined"
                        class="search-text"
                        bg-color="white"
                        hide-details="auto"
                        density="comfortable"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="kpiContent"
                        placeholder="KPIで探す"
                        variant="outlined"
                        class="search-text"
                        bg-color="white"
                        hide-details="auto"
                        density="comfortable"
                        :disabled="!selectedHealthManagement"
                      >
                      </v-text-field>
                    </v-col>
                    <v-col cols="2" class="search-tab-content">
                      <v-btn @click="searchCompanyByHealthManagementSearchBox" class="search-button" flat>
                        <v-icon left>mdi-magnify</v-icon>
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="2">
                      絞り込み
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showIndustryDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedIndustry}"
                            flat
                          >
                            業種
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedIndustry || '指定なし' }}
                            <span
                              v-if="selectedIndustry"
                              @click="clearSelectedIndustry"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="showStatusDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedStatus}"
                            flat
                          >
                            顕彰
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedStatus  || '指定なし' }}
                            <span
                              v-if="selectedStatus"
                              @click="clearSelectedStatus"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showCompanySizeDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedCompanySize}"
                            flat
                          >
                            従業員規模
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedCompanySize || '指定なし' }}
                            <span
                              v-if="selectedCompanySize"
                              @click="clearSelectedCompanySize"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
              <v-tab-item v-if="activeTab === 2" class="search-tab-content">
                <!-- 課題への対応での検索ボックス -->
                <v-form class="search-form" ref="searchFormHealthMeasure" @submit.prevent="searchCompanyByHealthMeasureSearchBox">
                  <p class="search-message">重点を置いている課題テーマ</p>
                  <v-row no-gutters class="search-text-row">
                    <v-col cols="10">
                      <v-select
                        v-model="selectedHealthMeasure"
                        :items="healthMeasureList"
                        placeholder="課題テーマで探す"
                        variant="outlined"
                        class="search-text"
                        bg-color="white"
                        hide-details="auto"
                        density="comfortable"
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2" class="search-tab-content">
                      <v-btn @click="searchCompanyByHealthMeasureSearchBox" class="search-button" flat>
                        <v-icon left>mdi-magnify</v-icon>
                        検索
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="2">
                      絞り込み
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showIndustryDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedIndustry}"
                            flat
                          >
                            業種
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedIndustry || '指定なし' }}
                            <span
                              v-if="selectedIndustry"
                              @click="clearSelectedIndustry"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                        <v-col cols="12">
                          <v-btn
                            @click="showStatusDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedStatus}"
                            flat
                          >
                            顕彰
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedStatus  || '指定なし' }}
                            <span
                              v-if="selectedStatus"
                              @click="clearSelectedStatus"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="5">
                      <v-row>
                        <v-col cols="12">
                          <v-btn
                            @click="showCompanySizeDialog = true"
                            class="narrowdown-button"
                            :class="{'selected': selectedCompanySize}"
                            flat
                          >
                            従業員規模
                          </v-btn>
                          <span class="selected-narrowdown">
                            {{ selectedCompanySize || '指定なし' }}
                            <span
                              v-if="selectedCompanySize"
                              @click="clearSelectedCompanySize"
                              class="clear-button"
                            >
                              <v-icon>mdi-close</v-icon>
                            </span>
                          </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-form>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row align="center" no-gutters style="height: 60px">
        <v-col cols="12">
          <v-sheet class="pa-2" v-if="!isLoading && !isLoadingError">
            <h3>{{ title }}<span class="company-count">{{ companyCount }}</span><span class="company-count-unit">社</span></h3>
          </v-sheet>
        </v-col>
      </v-row>
      <v-divider
        :thickness="3"
        class="border-opacity-75"
        color="#009688"
      ></v-divider>
      <!-- ページコントロール -->
      <div class="ma-5 text-center" v-if="!isLoading && !isLoadingError && paginatedCompanies.length > 0">
        <button @click="prevPage" :disabled="currentPage <= 1">
          <v-chip color="teal cursor-pointer">前のページ</v-chip>
        </button>
        <span class="mx-3"
          >{{ currentPage }}ページ / {{ totalPage }}ページ</span
        >
        <button @click="nextPage" :disabled="currentPage >= totalPages">
          <v-chip color="teal cursor-pointer">次のページ</v-chip>
        </button>
      </div>

    </div>
    <div class="companyList">
      <v-row align="center" justify="start">
        <template v-if="!isLoadingError">
          <template v-if="isLoading">
            <v-col cols="12" class="loading-container">
              <v-progress-circular indeterminate color="var(--main-green)" size="48" width="8" bg-color="var(--white)"></v-progress-circular>
            </v-col>
          </template>
          <template v-else>
            <template v-if="activeTab === 0">
              <!-- 企業名での検索結果 -->
              <!-- 各画面サイズに応じたカラム幅の設定 -->
              <v-col
                v-for="company in paginatedCompanies"
                :key="company.code"
                cols="12"
                sm="12"
                md="4"
                xl="4"
              >
                <v-list-item
                  @click="handleCompanyClick(company)"
                  :disabled="!company.submit_sheet_flag"
                  class="company-card"
                >
                  <v-card
                    class="mx-auto company-card-content fixed-height"
                    max-width="100%"
                    :company="company"
                    flat
                  >
                    <v-card-item>
                      <div>
                        <div class="text-h6 mb-1">
                          <b>{{ company.corporate_name }}</b>
                        </div>
                        <p>
                          {{ company.insurer_name }}
                        </p>
                        <div class="my-3">
                          <CompanyChips :companyInfo="company" size="x-small" />
                        </div>
                        <p v-if="!company.submit_sheet_flag" class="disabled-sheet">
                          【フィードバックシート非公開】
                        </p>
                        <div v-if="company.submit_sheet_flag" class="text-caption">
                          総合順位：{{ company.overall_rank }}
                          <RankIcon :rankText="company.overall_rank" />
                          / {{ numOfCompanyCount }}社中
                        </div>
                        <div v-if="company.submit_sheet_flag" class="text-caption">
                          総合評価：{{ company.overall_deviation_value }}
                        </div>
                      </div>
                      <div v-if="company.company_size" class="company-size">
                        <v-icon left color="var(--main-black)">mdi-account</v-icon>
                        <p>{{ company.company_size }}名</p>
                      </div>
                    </v-card-item>
                  </v-card>
                </v-list-item>
              </v-col>
            </template>
            <template v-else-if="activeTab === 1">
              <!-- 健康経営の戦略での検索結果 -->
              <!-- 各画面サイズに応じたカラム幅の設定 -->
              <v-col
                v-for="company in paginatedCompanies"
                :key="company.code"
                cols="12"
                sm="12"
                md="12"
                xl="12"
              >
                <v-list-item
                  @click="handleCompanyClick(company)"
                  :disabled="!company.submit_sheet_flag"
                  class="company-card"
                >
                  <v-card
                    class="mx-auto company-card-content"
                    max-width="100%"
                    :company="company"
                    flat
                  >
                    <v-card-item>
                      <v-row>
                        <v-col cols="4">
                          <div>
                            <div class="text-h6 mb-1">
                              <b>{{ company.corporate_name }}</b>
                            </div>
                            <p>
                              {{ company.insurer_name }}
                            </p>
                            <div class="my-3">
                              <CompanyChips :companyInfo="company" size="x-small" />
                            </div>
                            <p v-if="!company.submit_sheet_flag" class="disabled-sheet">
                              【フィードバックシート非公開】
                            </p>
                            <div v-if="company.submit_sheet_flag" class="text-caption">
                              総合順位：{{ company.overall_rank }}
                              <RankIcon :rankText="company.overall_rank" />
                              / {{ numOfCompanyCount }}社中
                            </div>
                            <div v-if="company.submit_sheet_flag" class="text-caption">
                              総合評価：{{ company.overall_deviation_value }}
                            </div>
                          </div>
                          <div v-if="company.company_size" class="company-size">
                            <v-icon left color="var(--main-black)">mdi-account</v-icon>
                            <p>{{ company.company_size }}名</p>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div v-if="selectedHealthManagementCode && company.health_management_issue_content" class="health-content">
                            <p><b>課題内容</b></p>
                            <p>{{ company.health_management_issue_content }}</p>
                            <br />
                            <p><b>健康経営の実施により期待する効果</b></p>
                            <p>{{ company.health_management_issue_content_effect }}</p>
                            <br />
                            <p><b>課題解決または効果に繋がるKPI</b></p>
                            <v-table class="kpi-table">
                              <tbody>
                                <tr>
                                  <td class="kpi-label"><p>KPIの概要</p></td>
                                  <td colspan="2"><p>{{ company.health_management_issue_content_kpi }}</p></td>
                                </tr>
                                <tr>
                                  <td class="kpi-label"><p>KPIの値</p></td>
                                  <td class="kpi-value">
                                    <div class="kpi-value-label"><p>現状値</p></div>
                                    <div><p>{{ company.health_management_issue_content_kpi_current }}</p></div>
                                  </td>
                                  <td class="kpi-value">
                                    <div class="kpi-value-label"><p>目標値</p></div>
                                    <div><p>{{ company.health_management_issue_content_kpi_target }}</p></div>
                                  </td>
                                </tr>
                              </tbody>
                            </v-table>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-list-item>
              </v-col>
            </template>
            <template v-else-if="activeTab === 2">
              <!-- 課題テーマでの検索結果 -->
              <!-- 各画面サイズに応じたカラム幅の設定 -->
              <v-col
                v-for="company in paginatedCompanies"
                :key="company.code"
                cols="12"
                sm="12"
                md="12"
                xl="12"
              >
                <v-list-item
                  @click="handleCompanyClick(company)"
                  :disabled="!company.submit_sheet_flag"
                  class="company-card"
                >
                  <v-card
                    class="mx-auto company-card-content"
                    max-width="100%"
                    :company="company"
                    flat
                  >
                    <v-card-item>
                      <v-row>
                        <v-col cols="4">
                          <div>
                            <div class="text-h6 mb-1">
                              <b>{{ company.corporate_name }}</b>
                            </div>
                            <p>
                              {{ company.insurer_name }}
                            </p>
                            <div class="my-3">
                              <CompanyChips :companyInfo="company" size="x-small" />
                            </div>
                            <p v-if="!company.submit_sheet_flag" class="disabled-sheet">
                              【フィードバックシート非公開】
                            </p>
                            <div v-if="company.submit_sheet_flag" class="text-caption">
                              総合順位：{{ company.overall_rank }}
                              <RankIcon :rankText="company.overall_rank" />
                              / {{ numOfCompanyCount }}社中
                            </div>
                            <div v-if="company.submit_sheet_flag" class="text-caption">
                              総合評価：{{ company.overall_deviation_value }}
                            </div>
                          </div>
                          <div v-if="company.company_size" class="company-size">
                            <v-icon left color="var(--main-black)">mdi-account</v-icon>
                            <p>{{ company.company_size }}名</p>
                          </div>
                        </v-col>
                        <v-col cols="8">
                          <div v-if="selectedHealthMeasureCode && company.health_issue_resolution_content" class="health-content">
                            <p><b>課題内容</b></p>
                            <p>{{ company.health_issue_resolution_content }}</p>
                            <br />
                            <p><b>施策実施結果</b></p>
                            <p>{{ company.health_issue_implementation }}</p>
                            <br />
                            <p><b>効果検証結果</b></p>
                            <p>{{ company.health_issue_effect_verification }}</p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-item>
                  </v-card>
                </v-list-item>
              </v-col>
            </template>
          </template>
        </template>
        <template v-else>
          <v-col cols="12" style="height: 50vh">
            <h3>
              企業データの取得に失敗しました。時間を空けて再度お試しください。
            </h3>
          </v-col>
        </template>
        <template v-if="!isLoading && !isLoadingError && paginatedCompanies.length == 0">
          <v-col cols="12" style="height: 50vh">
            <h3>
              検索条件に一致する企業が見つかりませんでした。
            </h3>
          </v-col>
        </template>
      </v-row>
      <!-- ページコントロール -->
      <div class="ma-5 text-center" v-if="!isLoading && !isLoadingError && paginatedCompanies.length > 0">
        <button @click="prevPage" :disabled="currentPage <= 1">
          <v-chip color="teal cursor-pointer">前のページ</v-chip>
        </button>
        <span class="mx-3"
          >{{ currentPage }}ページ / {{ totalPage }}ページ</span
        >
        <button @click="nextPage" :disabled="currentPage >= totalPages">
          <v-chip color="teal cursor-pointer">次のページ</v-chip>
        </button>
      </div>

    </div>
  </div>

  <!-- 絞り込み検索モーダル -->
  <IndustryNarrowdownDialog
    v-model:showDialog="showIndustryDialog"
    v-model:selectedIndustryCode="selectedIndustryCode"
    v-model:selectedIndustry="selectedIndustry"
  />
  <InsurerNarrowdownDialog
    v-model:showDialog="showInsurerDialog"
    v-model:selectedInsurer="selectedInsurer"
  />
  <StatusNarrowdownDialog
    v-model:showDialog="showStatusDialog"
    v-model:selectedStatusCode="selectedStatusCode"
    v-model:selectedStatus="selectedStatus"
  />
  <CompanySizeNarrowdownDialog
    v-model:showDialog="showCompanySizeDialog"
    v-model:selectedCompanySizeMin="selectedCompanySizeMin"
    v-model:selectedCompanySizeMax="selectedCompanySizeMax"
    v-model:selectedCompanySize="selectedCompanySize"
  />

</template>
<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
