<template>
  <CommonDialog
    :showDialog="showDialog"
    :title="title"
    @update:showDialog="closeDialog"
  >
    <span v-html="content"></span>
  </CommonDialog>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css" scoped></style>
