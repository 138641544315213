<template>
  <v-app-bar :color="mainGreen" dark app class="header" elevation="0">
    <div class="toolbar-title">
      <div class="header__title" @click="handleClick">
        WellCom
      </div>
      <div class="header_linkList">
        <v-btn text class="header_link" @click="openDialog(dialog.documents.title, dialog.documents.content)">
          関連する社内資料
        </v-btn>
        <v-btn text class="header_link" @click="openDialog(dialog.aboutData.title, dialog.aboutData.content)">
          データについて
        </v-btn>
        <v-btn
          v-for="nav in nav_lists"
          :key="nav.name"
          :href="nav.href"
          target="_blank"
          text
          class="header_link"
        >
          <v-icon left>mdi-open-in-new</v-icon> {{ nav.name }}
        </v-btn>
      </div>
    </div>

    <HeaderDialog
      v-model:showDialog="showDialog"
      :title="dialogTitle"
      :content="dialogContent"
    />
  </v-app-bar>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css"></style>
