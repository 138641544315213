import CommonDialog from '@/components/organisms/dialog/CommonDialog.vue';
import { fetchCompanyCountByIndustry } from "@/services/api/companyInfoService";

export default {
  name: 'IndustryNarrowdownDialog',
  components: {
    CommonDialog
  },
  props: {
    showDialog: {
      type: Boolean,
      required: true
    },
    selectedIndustryCode: {
      type: Number
    },
    selectedIndustry: {
      type: String
    }
  },
  data() {
    return {
      title: "業種を選択してください",
      companyCountList: [],
    }
  },
  methods: {
    closeDialog() {
      this.$emit('update:showDialog', false);
    },
    getCompanyCountByIndustry() {
      // 業種ごとの企業数を取得
      fetchCompanyCountByIndustry()
      .then((response) => {
        const data = response.data.industry_counts;
        this.companyCountList = Object.keys(data).map((key) => ({
          name: key,
          count: data[key],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
    },
    selectIndustry(industryCode, industryName) {
      this.$emit('update:selectedIndustryCode', industryCode);
      this.$emit('update:selectedIndustry', industryName);
      this.closeDialog();
    }
  },
  mounted() {
    this.getCompanyCountByIndustry();
  }
};
