<template>
  <v-app>
    <Header />
    <v-main>
      <div class="container">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script src="./script.js"></script>
<style src="./style.css" lang="css"></style>
